export const EDGE_CONNECTIONS = [
    {
        id: "cpl-cost",
        from: "cpl",
        to: "cost",
    },
    {
        id: "cpl-conversions",
        from: "cpl",
        to: "conversions",
    },
    {
        id: "conversions-conversionValue",
        from: "conversions",
        to: "conversionValue",
    },
    {
        id: "roas-conversionValue",
        from: "roas",
        to: "conversionValue",
    },
    {
        id: "roas-cost",
        from: "roas",
        to: "cost",
    },
    {
        id: "cpl-conversionsByConversionDate",
        from: "cpl",
        to: "conversionsByConversionDate",
    },
    {
        id: "conversions-conversionRate",
        from: "conversions",
        to: "conversionRate",
    },
    {
        id: "conversions-clicks",
        from: "conversions",
        to: "clicks",
    },
    {
        id: "conversions-viewThroughConv",
        from: "conversions",
        to: "viewThroughConv",
    },
    {
        id: "conversionsByConversionDate-conversionsValueByConversionDate",
        from: "conversionsByConversionDate",
        to: "conversionsValueByConversionDate",
    },
    {
        id: "clicks-cpc",
        from: "clicks",
        to: "cpc",
    },
    {
        id: "clicks-ctr",
        from: "clicks",
        to: "ctr",
    },
    {
        id: "clicks-impressions",
        from: "clicks",
        to: "impressions",
    },
    {
        id: "impressions-cpm",
        from: "impressions",
        to: "cpm",
    },
    {
        id: "impressions-views",
        from: "impressions",
        to: "views",
    },
    {
        id: "views-viewRate",
        from: "views",
        to: "viewRate",
    },
    {
        id: "views-cpv",
        from: "views",
        to: "cpv",
    },
];

export const EXCLUDE_FIELD_NODE = ["roasByTime", "phoneCalls", "phoneImpressions", "phoneThroughRate"];
export const SEARCH_TERM_UNCOUNTABLE_METRICS = ["campaign", "adGroup"];
export const DEFAULT_TOP_SEARCH_TERM_METRICS = ["clicks", "impressions", "ctr", "cost", "conversions", "cpl"];
