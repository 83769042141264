import React from "react";
import mixpanel from "mixpanel-browser";
import { getProfile, MorphioAPI } from "../../lib/api";

const token = process.env.REACT_APP_MIXPANEL_TOKEN;
mixpanel.init(token, {
    record_sessions_percent: 10, //records 10% of all sessions
    record_max_ms: 30 * 60000, //records sessions up to 30 minutes
});

export const sendToSlack = (message) => {
    if (process.env.NODE_ENV === "production" && !process.env.REACT_APP_STAGING) {
        const { email } = getProfile();

        const usermessage = `User: ${email} - ${message}`;

        return MorphioAPI({
            method: "post",
            url: "/tracker",
            headers: { "Content-type": "application/json" },
            data: {
                message: usermessage,
            },
        });
    }
};

const trackGoal = async (event, properties, { sendToSlack: isSending, additionalMessage }) => {
    if (isSending && process.env.NODE_ENV === "production" && !process.env.REACT_APP_STAGING) {
        const message = `Event ${event}${additionalMessage ? " " + additionalMessage : ""}`;
        await sendToSlack(message);
    }

    return mixpanel.track(event, properties);
};

export { mixpanel, trackGoal };

export default (Component) => (props) => {
    if (!token) console.warn("No mixpanel token is set.");

    return <Component _mixpanel={mixpanel} {...props} />;
};
