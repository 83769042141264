import { api } from "lib/organicInstagram";
import types from "./types";

const getOrganicInstagramInsightData = ({ dateRange, accountId, integrationId, abortController }) => (dispatch) => {
    return dispatch({
        type: types.ORGANIC_INSTAGRAM_INSIGHT_REPORT,
        promise: api.getReport({
            dateRange,
            accountId,
            integrationId,
            abortController,
        }),
        meta: {
            integrationId,
        },
    });
};

const getOrganicInstagramDemographicsData = ({
    accountId,
    types: demographicTypes,
    integrationId,
    abortController,
}) => (dispatch) => {
    return dispatch({
        type: types.ORGANIC_INSTAGRAM_DEMOGRAPHICS,
        promise: api.getDemographic({
            types: demographicTypes,
            accountId,
            integrationId,
            abortController,
        }),
        meta: {
            integrationId,
        },
    });
};

const getOrganicInstagramTopPosts = ({ startDate, endDate, accountId, integrationId, abortController }) => (
    dispatch,
) => {
    return dispatch({
        type: types.ORGANIC_INSTAGRAM_TOP_POSTS,
        promise: api.getTopPosts({
            startDate,
            endDate,
            accountId,
            integrationId,
            abortController,
        }),
        meta: {
            integrationId,
        },
    });
};

export default {
    getOrganicInstagramInsightData,
    getOrganicInstagramDemographicsData,
    getOrganicInstagramTopPosts,
};
