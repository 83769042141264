import React from "react";
import { get } from "lodash";
import moment from "moment";
import { ReactComponent as IconForecast } from "assets/images/Menu/Icon-Forecast.svg";
import { ReactComponent as IconBenchmark } from "assets/images/icons/Icon-DataBenchmarking.svg";
import { ReactComponent as IconExpectedRange } from "assets/images/icons/Icon-ExpectedRange.svg";
import { ADWORDS_NETWORKS } from "lib/adwords/networks";
import { COMPARISONS } from "lib/reports/campaigns/constants";

export { COMPARISONS };

export const COMPARISONS_LABELS = {
    [COMPARISONS.NONE]: "No Comparison",
    [COMPARISONS.FORECAST]: "Forecast",
    [COMPARISONS.BENCHMARK_NONE]: "Similar Industry",
    [COMPARISONS.BENCHMARK_MEDIA_SPEND]: "Similar Media Spend",
    [COMPARISONS.BENCHMARK_AOV]: "Similar AOV",
    [COMPARISONS.BENCHMARK_LOCATION]: "Similar Location",
    [COMPARISONS.EXPECTED_RANGE]: "Expected Range",
};

export const ANALYSIS_PLATFORMS = ["adwords", "facebook", "bing", "linkedin", "tiktok", "pinterest", "stackAdapt"];

export const EMAIL_PLATFORMS = ["maropost", "klaviyo"];

export const EMAIL_PLATFORM_METRICS = [
    "delivered",
    "opened",
    "openRate",
    "clicked",
    "ctr",
    "ctor",
    "conversions",
    "conversionRate",
];

export const SHOPIFY_METRICS = [
    "revenue",
    "grossSales",
    "netSales",
    "totalSales",
    "gmv",
    "transactions",
    "items",
    "itemsPerTransaction",
    "avgOrderValue",
    "averageSalePrice",
    "discounts",
    "shipping",
    "taxes",
    "returns",
];

export const MAILCHIMP_METRICS = ["recipients", "opens", "openRate", "clicks", "clickRate", "bounces", "bounceRate"];

export const CUSTOM_ADDITIONAL_ANALYSIS_METRICS = {
    "custom-ctr": {
        id: "custom-ctr",
        format: "number",
        formula: "100*(A/B)",
        metrics: {
            A: "custom-paid-clicks",
            B: "custom-impressions",
        },
    },
    "custom-cpc": {
        id: "custom-cpc",
        format: "number",
        formula: "A/B",
        metrics: {
            A: "custom-cost",
            B: "custom-paid-clicks",
        },
    },
    "custom-cpm": {
        id: "custom-cpm",
        format: "number",
        formula: "1000*(A/B)",
        metrics: {
            A: "custom-cost",
            B: "custom-impressions",
        },
    },
    "custom-cpl": {
        id: "custom-cpl",
        format: "number",
        formula: "A/B",
        metrics: {
            A: "custom-cost",
            B: "custom-conversions",
        },
    },
    "custom-conversionRate": {
        id: "custom-conversionRate",
        format: "number",
        formula: "100*(A/B)",
        metrics: {
            A: "custom-conversions",
            B: "custom-paid-clicks",
        },
    },
};
export const BENCHMARK_COMPARE_METRICS = [
    "ctr",
    "cpl",
    "conversionRate",
    "transactionRate",
    "roas",
    "aov",
    "avgOrderValue",
    "engagementRate",
    "cpc",
    "cpm",
];

export const PLATFORM_EXTRA_ANALYSIS_METRICS = {
    facebook: ["leadGrouped"],
};
export const getNetworkFriendlyName = (network) => {
    const allNetworks = { ...ADWORDS_NETWORKS };
    return get(allNetworks, [network, "friendlyName"]) || network;
};
export const DEFAULT_PLATFORM_OPTION = {
    networks: [],
};

export const NONE_HEATMAP_PREVIOUS_COMPARE_METRICS = ["cost", "impressions"];

export const campaignGroupsFormatter = (campaignGroups = {}) =>
    Object.keys(campaignGroups).reduce((cache, key) => {
        return {
            ...cache,
            [`campaignGroup${key}`]: campaignGroups[key],
        };
    }, {});

export const getCompareAdditionalOptions = ({ changeCompare }) => [
    {
        start: null,
        end: null,
        label: COMPARISONS_LABELS[COMPARISONS.NONE],
        id: COMPARISONS_LABELS[COMPARISONS.NONE],
        onClick: () => changeCompare({ compare: COMPARISONS.NONE }),
        deactive: false,
    },
    {
        start: moment()
            .add(-1, "month")
            .startOf("month"),
        end: moment()
            .add(-1, "month")
            .endOf("month"),
        label: COMPARISONS_LABELS[COMPARISONS.FORECAST],
        id: COMPARISONS_LABELS[COMPARISONS.FORECAST],
        onClick: () =>
            changeCompare({
                compare: COMPARISONS.FORECAST,
            }),
        icon: <IconForecast />,
        tip: "See our AI-powered predictions for your future performance.",
    },
    {
        start: null,
        end: null,
        label: COMPARISONS_LABELS[COMPARISONS.BENCHMARK_NONE],
        id: COMPARISONS_LABELS[COMPARISONS.BENCHMARK_NONE],
        onClick: () =>
            changeCompare({
                compare: COMPARISONS.BENCHMARK_NONE,
            }),
        deactive: false,
        icon: <IconBenchmark />,
        tip: `View industry benchmark ranges for each "quality" metric`,
    },
    {
        start: null,
        end: null,
        label: COMPARISONS_LABELS[COMPARISONS.BENCHMARK_MEDIA_SPEND],
        id: COMPARISONS_LABELS[COMPARISONS.BENCHMARK_MEDIA_SPEND],
        onClick: () =>
            changeCompare({
                compare: COMPARISONS.BENCHMARK_MEDIA_SPEND,
            }),
        deactive: false,
        icon: <IconBenchmark />,
        tip: `View industry benchmark ranges for each "quality" metric`,
    },
    {
        start: null,
        end: null,
        label: COMPARISONS_LABELS[COMPARISONS.BENCHMARK_AOV],
        id: COMPARISONS_LABELS[COMPARISONS.BENCHMARK_AOV],
        onClick: () =>
            changeCompare({
                compare: COMPARISONS.BENCHMARK_AOV,
            }),
        deactive: false,
        icon: <IconBenchmark />,
        tip: `View industry benchmark ranges for each "quality" metric`,
    },
    {
        start: null,
        end: null,
        label: COMPARISONS_LABELS[COMPARISONS.BENCHMARK_LOCATION],
        id: COMPARISONS_LABELS[COMPARISONS.BENCHMARK_LOCATION],
        onClick: () =>
            changeCompare({
                compare: COMPARISONS.BENCHMARK_LOCATION,
            }),
        deactive: false,
        icon: <IconBenchmark />,
        tip: `View industry benchmark ranges for each "quality" metric`,
    },
    {
        start: null,
        end: null,
        label: COMPARISONS_LABELS[COMPARISONS.EXPECTED_RANGE],
        id: COMPARISONS_LABELS[COMPARISONS.EXPECTED_RANGE],
        onClick: () =>
            changeCompare({
                compare: COMPARISONS.EXPECTED_RANGE,
            }),
        deactive: false,
        icon: <IconExpectedRange />,
        tip: "Compare current performance to an expected range.",
    },
];
