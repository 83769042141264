import { cleanCampaignGroupId } from "lib/utility";
import { bigQueryHelpers } from "lib/bigQuery";
import { createSelector } from "reselect";
import { defaultState } from "./";
import { isEmpty, omit } from "lodash";

export const selectSites = (state = defaultState) => state.sites;

export const selectSelectedSite = ({ sites = {} } = {}) => {
    return sites.selectedSite || defaultState.selectedSite;
};

//********************* */
//
// Root sites state selectors
//
//********************* */

export const makeSelectAccountLoading = () => createSelector(selectSites, ({ sitePending }) => sitePending);
export const makeSelectMetaDataUpdating = () => createSelector(selectSites, ({ updatingMetaData }) => updatingMetaData);

//********************* */
//
//  Site Selectors
//
//********************* */

export const makeSelectAccountId = () => createSelector(selectSelectedSite, ({ _id }) => _id);

export const makeSelectAccountStatus = () => createSelector(selectSelectedSite, ({ status }) => status);

export const makeSelectAccountName = () => createSelector(selectSelectedSite, ({ name }) => name);

export const makeSelectAccountBranding = () => createSelector(selectSelectedSite, ({ branding }) => branding);

export const makeSelectAccountLogo = () => createSelector(selectSelectedSite, ({ logo }) => logo);

export const makeSelectAccountCreatedAt = () =>
    createSelector(selectSelectedSite, ({ created_at }) => created_at || "2022-01-01"); // For the older accounts we assume they are created 2022-01-01

export const makeSelectAccountCurrency = () => createSelector(selectSelectedSite, ({ currency = "USD" }) => currency);

export const makeSelectAccountAlertRules = () =>
    createSelector(selectSelectedSite, ({ alertRules = {} }) => alertRules);

export const makeSelectKpiSummarySettings = () =>
    createSelector(selectSelectedSite, ({ kpiSummarySettings = {} }) => {
        if (isEmpty(kpiSummarySettings)) {
            return {};
        }
        const { cards = {}, sections = {} } = kpiSummarySettings || {};
        return {
            cards,
            sections: Object.keys(sections).reduce((cache, sectionId) => {
                const section = sections[sectionId];
                return { ...cache, [sectionId]: omit(section, ["isCollapsed"]) };
            }, {}),
        };
    });

export const selectAccountMetadata = createSelector(selectSelectedSite, ({ metadata = {} }) => metadata);

export const makeSelectAccountAutoPlacementExclusion = createSelector(
    selectSelectedSite,
    ({ autoPlacementExclusion }) => autoPlacementExclusion || {},
);

export const selectAccountFacebookConversionTypes = createSelector(
    selectSelectedSite,
    ({ facebook: { conversions } = {} }) => conversions || {},
);

export const selectAccountAdwordsConversionTypes = createSelector(
    selectSelectedSite,
    ({ adwords: { conversions } = {} }) => conversions || {},
);

export const selectAccountLinkedinConversionTypes = createSelector(
    selectSelectedSite,
    ({ linkedin: { conversions } = {} }) => conversions || {},
);
//********************* */
//
//  Site Meta Data Selectors
//
//********************* */

export const makeSelectTouringState = () =>
    createSelector(makeSelectAccountStatus(), ({ run_touring = false } = {}) => run_touring);

export const makeSelectAccountAnomalySettings = () =>
    createSelector(selectSelectedSite, ({ anomaly_settings }) => {
        return anomaly_settings || {};
    });

export const makeSelectSeo = () => createSelector(selectSelectedSite, ({ seo = {} }) => seo);

export const makeSelectIsCompetitorSetup = () =>
    createSelector(makeSelectSeo(), ({ keywords = [], competitors = [], domain }) => {
        return domain && competitors.length > 0 && keywords.length > 0;
    });

export const makeSelectMarginRules = () =>
    createSelector(selectSelectedSite, ({ margin_rules }) => {
        return margin_rules || {};
    });

export const makeSelectMarginRulesHistory = () =>
    createSelector(selectSelectedSite, ({ margin_rules_history }) => {
        return margin_rules_history || {};
    });

export const selectLastDashboardLink = (state) => state.sites.lastLink;

export const makeSelectIsDemo = () => createSelector(selectSelectedSite, ({ isDemo }) => isDemo);
export const makeSelectNotificationSettings = () =>
    createSelector(selectSelectedSite, ({ notifications }) => notifications || {});
export const makeSelectSlackChannels = () =>
    createSelector(selectSelectedSite, ({ integrations }) => {
        const { slack: slackIntegrations = {} } = integrations || {};
        const [{ channels: slackChannels = {} } = {}] = Object.values(slackIntegrations) || [];
        const slackChannelArray = Object.keys(slackChannels).map((key) => slackChannels[key]?.channel);
        return slackChannelArray;
    });
/**
 *
 * @param {array} generating
 *  list of generating names
 *  [daily_anomalies
 *  weekly_anomalies
 *  weekly_kpi_anomalies
 *  monthly_anomalies
 *  forecasting
 *  competitors
 *  health_checks
 *  seo_anomalies]
 *
 *  @returns {boolean} {Boolean} if is generating
 */

export const makeSelectIsGenerating = (generating) =>
    createSelector(makeSelectAccountStatus(), (status = {}) => {
        return generating.some((type) => {
            return status[`is_generating_${type}`] || false;
        });
    });

//********************* */
//
//  Site Goals
//
//********************* */

export const makeSelectGoals = () => createSelector(selectSelectedSite, ({ goals }) => goals || {});

export const makeSelectSavedGoals = () => createSelector(makeSelectGoals(), ({ metrics }) => metrics || {});

export const makeSelectSavedGoalValues = () => createSelector(makeSelectGoals(), ({ values }) => values || {});

export const makeSelectConversionMeasurement = () =>
    createSelector(makeSelectGoals(), ({ conversion_measurement }) => conversion_measurement);

export const makeSelectIsConversionMeasurement = (measurement) =>
    createSelector(makeSelectConversionMeasurement, (stateMeasurement) => stateMeasurement === measurement);

//********************* */
//
//  Site SEO Settings
//
//********************* */

export const makeSelectSeoDomain = () => createSelector(selectSelectedSite, ({ seo = {} } = {}) => seo.domain);

export const makeSelectSeoKeywords = () =>
    createSelector(selectSelectedSite, ({ seo = {} } = {}) => seo.keywords || []);

export const makeSelectSeoCompetitors = () =>
    createSelector(selectSelectedSite, ({ seo = {} } = {}) => seo.competitors || []);

export const makeSelectSeoCity = () =>
    createSelector(selectSelectedSite, ({ seo = {} } = {}) => seo.city || "CA-2,California,United States");

export const makeSelectSeoUpdateDomainPending = () =>
    createSelector(selectSelectedSite, ({ updateDomainPending = false } = {}) => updateDomainPending);

export const makeSelectSeoUpdateDomainError = () =>
    createSelector(selectSelectedSite, ({ updateDomainError = false } = {}) => updateDomainError);

export const makeSelectSiteConnections = () =>
    createSelector(selectSelectedSite, ({ connections }) => connections || {});

//********************* */
//
//  Integrations
//
//********************* */

export const makeSelectIntegrationIds = () =>
    createSelector(selectSelectedSite, ({ integrations = {} }) => {
        // To remove Universal Analytics
        const { analytics, ...newIntegrations } = integrations;
        return newIntegrations || {};
    });

export const makeSelectCampaignGroupsKpiMetrics = () =>
    createSelector(selectSelectedSite, ({ campaignGroups = {} }) => {
        return Object.keys(campaignGroups).reduce((cache, campaignGroupId) => {
            return {
                ...cache,
                [`campaignGroup${cleanCampaignGroupId(campaignGroupId)}-cost`]: {},
                [`campaignGroup${cleanCampaignGroupId(campaignGroupId)}-conversions`]: {},
                [`campaignGroup${cleanCampaignGroupId(campaignGroupId)}-conversionRate`]: {},
                [`campaignGroup${cleanCampaignGroupId(campaignGroupId)}-cpl`]: {},
                [`campaignGroup${cleanCampaignGroupId(campaignGroupId)}-clicks`]: {},
                [`campaignGroup${cleanCampaignGroupId(campaignGroupId)}-cpc`]: {},
                [`campaignGroup${cleanCampaignGroupId(campaignGroupId)}-impressions`]: {},
                [`campaignGroup${cleanCampaignGroupId(campaignGroupId)}-cpm`]: {},
                [`campaignGroup${cleanCampaignGroupId(campaignGroupId)}-ctr`]: {},
                [`campaignGroup${cleanCampaignGroupId(campaignGroupId)}-revenue`]: {},
                [`campaignGroup${cleanCampaignGroupId(campaignGroupId)}-roas`]: {},
                [`campaignGroup${cleanCampaignGroupId(campaignGroupId)}-addToCart`]: {},
                [`campaignGroup${cleanCampaignGroupId(campaignGroupId)}-costPerAddToCart`]: {},
                [`campaignGroup${cleanCampaignGroupId(campaignGroupId)}-purchases`]: {},
                [`campaignGroup${cleanCampaignGroupId(campaignGroupId)}-costPerPurchase`]: {},
                [`campaignGroup${cleanCampaignGroupId(campaignGroupId)}-aov`]: {},
            };
        }, {});
    });

export const makeSelectBigQueryKpiMetrics = () =>
    createSelector(selectSelectedSite, ({ integrations = {} }) => {
        const { bigQuery: bigQueryIntegrations = {} } = integrations;
        return bigQueryHelpers.getBigQueryKpiMetrics(bigQueryIntegrations);
    });

export const makeSelectGa4KpiMetrics = () =>
    createSelector(selectSelectedSite, ({ analyticsV4: { conversions = {} } = {} }) => {
        let obj = {};
        Object.keys(conversions).forEach((integrationId) => {
            Object.keys(conversions[integrationId]).forEach((goalId) => {
                obj[`${integrationId}_${goalId}`] = {};
            });
        });
        return obj;
    });

export const makeSelectCampaignGroupsKpiPlatformKeys = () =>
    createSelector(selectSelectedSite, ({ campaignGroups = {} }) => {
        return Object.keys(campaignGroups).map((campaignGroupId) => {
            return `campaignGroup${cleanCampaignGroupId(campaignGroupId)}`;
        });
    });

export const makeSelectBigQueryKpiIntegrations = () =>
    createSelector(selectSelectedSite, ({ integrations = {} }) => {
        const { bigQuery: bigQueryIntegrations = {} } = integrations;
        return bigQueryIntegrations;
    });

export const makeSelectCampaignGroups = () =>
    createSelector(selectSelectedSite, ({ campaignGroups = {} }) => campaignGroups);

export const selectIntegratedPlatforms = createSelector(makeSelectIntegrationIds(), (integrations) =>
    Object.keys(integrations),
);

export const makeSelectShopifyIntegration = () =>
    createSelector(makeSelectIntegrationIds(), ({ shopify }) => shopify || {});

export const makeSelectIsShopifyConnected = () =>
    createSelector(makeSelectShopifyIntegration(), ({ clientId, secret, shop }) => {
        return clientId && secret && shop;
    });

export const makeSelectGetIntegrationId = (platform) =>
    createSelector(makeSelectIntegrationIds(), (integrations = {}) => integrations[platform] || {});

export const makeSelectGetIntegrationIdList = (platform) =>
    createSelector(makeSelectGetIntegrationId(platform), (integrations) => {
        return Object.keys(integrations).map((integration) => integrations[integration]?.integrationId.toString());
    });

export const makeSelectGetBigQueryIntegrationIdList = () =>
    createSelector(makeSelectGetIntegrationId("bigQuery"), (integrations) => {
        return Object.keys(integrations).map(
            (integration) =>
                `${integrations[integration]?.integrationId.toString()}.${integrations[
                    integration
                ]?.bigQueryTable.value.toString()}`,
        );
    });

export const makeSelectGetIntegration = (platform, integrationId) =>
    createSelector(makeSelectGetIntegrationId(platform), (integrations) => integrations[integrationId] || {});

export const makeSelectGetIntegrationCampaigns = (platform, integrationId) =>
    createSelector(makeSelectGetIntegration(platform, integrationId), ({ campaigns = {} }) => campaigns);

export const makeSelectIntegrationCampaignArray = (platform, integrationId) =>
    createSelector(makeSelectGetIntegrationCampaigns(platform, integrationId), (campaigns) =>
        Object.keys(campaigns).reduce((cache, key) => {
            const { id, name } = campaigns[key];
            return {
                ...cache,
                [`${name}____${id}`]: true,
            };
        }, {}),
    );

export const makeSelectConnections = () => createSelector(selectSelectedSite, ({ connections }) => connections);

export const makeSelectSeoAuditSettings = () =>
    createSelector(selectSelectedSite, ({ seo = {} } = {}) => seo.seoAuditSettings || {});

/**
 * Check platform permissions.
 *
 * NOTES: make function more performant? / different API?
 * @param {string} platform {string} single platform
 *
 * @return {object} {object} returns an object when platform is not present
 * @return {boolean} {boolean} returns a boolean when platform param is present
 */
export const makeSelectHasPlatforms = (platform) =>
    createSelector(
        [makeSelectIntegrationIds()],
        ({
            analyticsV4 = {},
            bing = {},
            adwords = {},
            facebook = {},
            linkedin = {},
            tiktok = {},
            pinterest = {},
            shopify = {},
            maropost = {},
            klaviyo = {},
            searchConsole = false,
            googleBusinessProfile = false,
            slack = false,
            bigQuery = false,
            organicFacebook = {},
            organicInstagram = {},
            stackAdapt = {},
            mailchimp = {},
        } = {}) => {
            const permissions = {
                analyticsV4: Object.keys(analyticsV4).length > 0,
                bing: Object.keys(bing).length > 0,
                adwords: Object.keys(adwords).length > 0,
                facebook: Object.keys(facebook).length > 0,
                linkedin: Object.keys(linkedin).length > 0,
                tiktok: Object.keys(tiktok).length > 0,
                pinterest: Object.keys(pinterest).length > 0,
                shopify: Object.keys(shopify).length > 0,
                maropost: Object.keys(maropost).length > 0,
                klaviyo: Object.keys(klaviyo).length > 0,
                slack: slack,
                searchConsole: searchConsole,
                googleBusinessProfile: Object.keys(googleBusinessProfile).length > 0,
                bigQuery: Object.keys(bigQuery).length > 0,
                organicFacebook: Object.keys(organicFacebook).length > 0,
                organicInstagram: Object.keys(organicInstagram).length > 0,
                stackAdapt: Object.keys(stackAdapt).length > 0,
                mailchimp: Object.keys(mailchimp).length > 0,
            };

            if (typeof platform === "string") {
                if (!permissions.hasOwnProperty(platform)) return false;

                return permissions[platform];
            }

            return permissions;
        },
    );

export const makeSelectGoogleBusinessProfileAccount = () =>
    createSelector(
        selectSelectedSite,
        ({ integrations: { googleBusinessProfile = {} } = {} } = {}) => googleBusinessProfile,
    );

export const makeSelectSearchConsoleConnection = () =>
    createSelector(selectSelectedSite, ({ integrations: { searchConsole = {} } = {} } = {}) => searchConsole);

export const makeSelectSearchConsoleDomain = () =>
    createSelector(selectSelectedSite, ({ integrations: { searchConsole: integration = {} } = {} } = {}) => {
        const [{ siteUrl } = {}] = Object.values(integration);
        return siteUrl;
    });

// Combine currencies across ALL passed platforms
export const makeSelectAccountCurrencies = (platforms) =>
    createSelector(selectSelectedSite, ({ integrations = {} } = {}) => {
        const currencies = [
            ...new Set(
                [].concat(
                    ...platforms
                        .filter((platform) => integrations[platform])
                        .map((platform) => {
                            const allCurrencies = Object.values(integrations[platform]).map(({ currency }) => currency);
                            return allCurrencies;
                        }),
                ),
            ),
        ];
        return currencies.filter((currency) => currency);
    });

// Get all currencies within a SINGLE platform
export const makeSelectAccountCurrenciesByPlatform = (platform) =>
    createSelector(selectSelectedSite, ({ integrations = {} } = {}) => {
        const currencies = Object.values(integrations[platform] || {}).map(({ currency } = {}) => currency);
        return [...new Set(currencies)];
    });

// Check if ANY of the passed platforms have mixed curriences WITHIN them
export const makeSelectAccountPlatformsHaveMixedCurrencies = (platforms) =>
    createSelector(selectSelectedSite, ({ integrations = {} } = {}) => {
        const currencies = platforms
            .filter((platform) => integrations[platform])
            .map((platform) => {
                const allCurrencies = Object.values(integrations[platform]).map(({ currency }) => currency);
                return {
                    platform,
                    currencyLength: [...new Set(allCurrencies)].length,
                };
            });

        return currencies.some(({ currencyLength }) => currencyLength > 1);
    });

export const makeSelectPageHealthSettings = () =>
    createSelector(selectSelectedSite, ({ pagehealth = {} } = {}) => pagehealth.pageHealthSettings || {});

export const makeSelectFacebookAttribution = () =>
    createSelector(selectSelectedSite, ({ facebook: { attribution = {} } = {} } = {}) => attribution);

export const makeSelectPinterestAttribution = () =>
    createSelector(selectSelectedSite, ({ pinterest: { attribution = {} } = {} } = {}) => attribution);

export const makeSelectBudgetThresholdPercent = () =>
    createSelector(
        selectSelectedSite,
        ({ alertSettings: { pacingSettings: { budgets: { deviation = 10 } = {} } = {} } = {} } = {}) =>
            parseFloat(deviation) / 100,
    );

export const makeSelectSelectedDashboardKpiCards = () =>
    createSelector(
        selectSelectedSite,
        ({
            selectedDashboardKpiCards = {
                "0": "custom-cost",
                "1": "custom-clicks",
                "2": "custom-conversions",
                "3": "custom-paid-clicks",
            },
        } = {}) =>
            Array.isArray(selectedDashboardKpiCards)
                ? selectedDashboardKpiCards.reduce(
                      (cache, metric, index) => ({
                          ...cache,
                          [index + ""]: metric,
                      }),
                      {},
                  )
                : selectedDashboardKpiCards,
    );

export const makeSelectSavedKpiSummaryMetrics = () =>
    createSelector(
        selectSelectedSite,
        ({
            selectedKpiSummaryMetrics = {
                "custom-cost": true,
                "custom-clicks": true,
                "custom-conversions": true,
                "custom-paid-clicks": true,
            },
        } = {}) =>
            Object.keys(selectedKpiSummaryMetrics).reduce((cache, metric) => {
                if (metric.startsWith("analytics") && !metric.startsWith("analyticsV4")) {
                    return {
                        ...cache,
                    };
                }
                return {
                    ...cache,
                    [metric]: { active: selectedKpiSummaryMetrics[metric] },
                };
            }, {}),
    );

export const makeSelectIsAnalyzingData = () =>
    createSelector(selectSelectedSite, ({ analyzingData = { active: false, lastRun: null } } = {}) => analyzingData);

export const makeSelectChatbotIsOpen = () =>
    createSelector(selectSelectedSite, ({ chatbot: { isOpen = false } = {} } = {}) => isOpen);

export const makeSelectOrganicFacebookIntegration = () =>
    createSelector(makeSelectIntegrationIds(), ({ organicFacebook }) => organicFacebook || {});

export const makeSelectOrganicInstagramIntegration = () =>
    createSelector(makeSelectIntegrationIds(), ({ organicInstagram }) => organicInstagram || {});
