import { ADWORDS_NETWORK_KEYS } from "lib/adwords/networks";
import { MAILCHIMP_METRICS } from "lib/mailchimp/metrics";
import { flatten } from "lodash";

export const COMPARISONS = {
    NONE: "none",
    FORECAST: "forecast",
    BENCHMARK_NONE: "industryBenchmark-none",
    BENCHMARK_MEDIA_SPEND: "industryBenchmark-mediaSpend",
    BENCHMARK_AOV: "industryBenchmark-aov",
    BENCHMARK_LOCATION: "industryBenchmark-location",
    EXPECTED_RANGE: "expectedRange",
};

export const ANALYSIS_METRICS = [
    "cost",
    "impressions",
    "clicks",
    "conversions",
    "ctr",
    "cpc",
    "cpm",
    "cpl",
    "conversionRate",
    "roas",
    "revenue",
];

export const ANALYSIS_PLATFORMS_OPTIONS = {
    campaignGroups: {
        additionalMetrics: [],
        networks: [],
    },
    adwords: {
        additionalMetrics: [
            "viewThroughConv",
            "conversionValue",
            "conversionsByConversionDate",
            "conversionsValueByConversionDate",
            "views",
            "roas",
            "roasByTime",
            "phoneCalls",
            "phoneImpressions",
            "phoneThroughRate",
            "cpv",
            "viewRate",
        ],
        networks: ADWORDS_NETWORK_KEYS,
    },
    facebook: {
        additionalMetrics: [
            "clicksAll",
            "engagement",
            "outboundClicks",
            "outboundCtr",
            "aov",
            "cpp",
            "websiteCpp",
            "metaCpp",
            "fb_pixel_lead",
            "costPer_fb_pixel_lead",
            "fb_pixel_lead_value",
            "fb_pixel_purchase",
            "onsite_conversion-purchase",
            "purchases",
            "fb_pixel_purchase_value",
            "costPerPurchase",
            "onsite_conversion-purchase_value",
            "purchaseValue",
            "landingPageViews",
            "fb_pixel_add_to_cart",
            "roas",
            "websiteRoas",
            "metaRoas",
            "leadGrouped",
            "contentViews",
            "cpcAll",
            "ctrAll",
        ],
        networks: [],
    },
    bing: {
        additionalMetrics: [],
        networks: [],
    },
    linkedin: {
        additionalMetrics: [
            "oneClickLeads",
            "oneClickLeadsConversionRate",
            "costPerOneClickLeads",
            "oneClickLeadFormOpens",
            "viewConversions",
            "clickConversions",
            "engagement",
            "engagementRate",
            "costPerClickAndView",
        ],
        networks: [],
    },
    tiktok: {
        additionalMetrics: [
            "videoViews",
            "averageWatchTime",
            "videoViewsAt50",
            "watched2sVideo",
            "reach",
            "frequency",
            "salesLead",
            "pageViews",
            "costPerPageView",
            "subscribes",
            "addToCart",
            "costPerAddToCart",
            "completePayments",
            "costPerCompletePayment",
            "checkouts",
            "costPerCheckout",
            "productViews",
            "costPerProductView",
            "revenue",
            "roas",
        ],
        networks: [],
    },
    pinterest: {
        additionalMetrics: [
            "totalEngagements",
            "earnedEngagements",
            "paidEngagements",
            "reach",
            "frequency",
            "pageVisit",
            "addToCart",
            "costPerAddToCart",
            "checkout",
            "costPerCheckout",
            "totalOrderValue",
            "totalROAS",
        ],
        networks: [],
    },
    stackAdapt: {
        additionalMetrics: [
            "engagements",
            "secondaryConversions",
            "conversionRevenue",
            "videoStarts",
            "videoCompletions",
            "uniqueImpressions",
            "revenueFee",
            "cpe",
            "roas",
            "videoCompletionRate",
        ],
        networks: [],
    },
};

export const getAnalysisPlatformsOptions = (platform) => {
    if (!platform) {
        return {};
    }

    const platformName = platform.startsWith("campaignGroup") ? "campaignGroups" : platform;

    return ANALYSIS_PLATFORMS_OPTIONS[platformName] || {};
};

export const getChannelMetrics = (channel) => {
    if (!channel) {
        return [];
    }
    const channelFormatted = Array.isArray(channel) ? channel[0] : channel;
    const validPlatformName = channelFormatted.startsWith("adwords") ? "adwords" : channelFormatted;

    if (validPlatformName === "mailchimp") {
        return MAILCHIMP_METRICS;
    }

    return [...ANALYSIS_METRICS, ...(getAnalysisPlatformsOptions(validPlatformName)?.additionalMetrics || [])];
};

export const getAllAdwordsMetrics = () => {
    const metrics = [...ANALYSIS_METRICS, ...ANALYSIS_PLATFORMS_OPTIONS.adwords.additionalMetrics];
    const allMetrics = ["adwords", ...ADWORDS_NETWORK_KEYS].map((network) =>
        metrics.map((metric) => `${network}-${metric}`),
    );
    return flatten(allMetrics);
};
