import "react-app-polyfill/ie11";
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import jwtDecode from "jwt-decode";
import moment from "moment";
import { Provider } from "react-redux";
import Routes, { exposedRoutes } from "./routes.jsx";
import { store, history } from "./_redux";
import PermissionsProvider from "./features/Permissions/ProviderContainer";
import { _getIdToken, ID_TOKEN_KEY } from "./lib/auth";
import { ConnectedRouter } from "connected-react-router";
import { history as browserHistory } from "./_redux";
import { trackUrls } from "lib/utility";

const RELOAD_TIMEOUT = 2000;

const App = () => {
    useEffect(() => {
        let reloadTimeout;

        const handleStorageChange = (event) => {
            if (event.key === ID_TOKEN_KEY && !event.newValue) {
                // Logout
                window.location.reload();
                return;
            }

            if (event.key === ID_TOKEN_KEY && event.newValue && event.newValue !== event.oldValue) {
                // Login
                reloadTimeout = setTimeout(() => window.location.reload(), RELOAD_TIMEOUT);
            }
        };

        window.addEventListener("storage", handleStorageChange);

        return function cleanup() {
            clearTimeout(reloadTimeout);
            window.removeEventListener("storage", handleStorageChange);
        };
    }, []);

    return (
        <Provider store={store}>
            <PermissionsProvider>
                <MuiThemeProvider>
                    <ConnectedRouter history={history}>
                        <Routes />
                    </ConnectedRouter>
                </MuiThemeProvider>
            </PermissionsProvider>
        </Provider>
    );
};

ReactDOM.render(<App />, document.getElementById("app"));

// Google Analytics
history.listen(({ pathname, search, ...rest } = {}, action) => {
    trackUrls({ pathname, action, search });
});

function validateUserSession({ pathname, search }) {
    const exemptedRoutes = exposedRoutes.filter((route) => route.path !== "/dashboard").map((route) => route.path);

    if (exemptedRoutes.includes(pathname)) {
        return;
    }

    let sessionExpiryDate;
    try {
        const { exp } = jwtDecode(_getIdToken());

        sessionExpiryDate = moment.unix(exp);
    } catch (e) {
        sessionExpiryDate = moment();
    }

    const tokenValid = moment().isBefore(sessionExpiryDate);

    if (tokenValid) {
        return;
    }

    return browserHistory.replace({
        pathname: "/logout",
        search: `redirect=${pathname}${search}`,
    });
}

history.listen(validateUserSession);
