import React from "react";
import style from "./style.module.scss";
import IconGoogleAds from "assets/images/Integrations/JSX/Logo-Ads";
import IconGoogleAdsGrey from "assets/images/icons/JSX/Google-Ads-Grey";
import IconFacebook from "assets/images/Integrations/JSX/Logo-Facebook";
import IconPinterest from "assets/images/Integrations/JSX/Logo-Pinterest";
import IconMaropost from "assets/images/Integrations/JSX/Logo-Maropost";
import IconKlaviyo from "assets/images/Integrations/JSX/Logo-Klaviyo";
import IconBigQuery from "assets/images/Integrations/JSX/Logo-BigQuery";
import IconBigQueryGrey from "assets/images/Integrations/JSX/Logo-BigQuery-Grey";
import IconFacebookGrey from "assets/images/icons/JSX/Icon-Facebook-Grey";
import IconMaropostGrey from "assets/images/icons/JSX/Icon-Maropost-Grey";
import IconKlaviyoGrey from "assets/images/icons/JSX/Icon-Klaviyo-Grey";
import IconTikTokGrey from "assets/images/icons/JSX/Icon-TikTok-Grey";
import IconPinterestGrey from "assets/images/icons/JSX/Icon-Pinterest-Grey";
import IconLinkedin from "assets/images/icons/JSX/Icon-Linkedin";
import { ReactComponent as IconTikTok } from "assets/images/icons/Icon-TikTok.svg";
import IconLinkedinGrey from "assets/images/icons/JSX/Icon-LinkedIn-Grey";
import IconBing from "assets/images/Integrations/JSX/Logo-Bing";
import IconBingGrey from "assets/images/icons/JSX/Icon-Microsoft-Grey";
import SearchConsoleIcon from "assets/images/Integrations/JSX/Logo-Google";
import SearchConsoleIconGrey from "assets/images/Integrations/JSX/Logo-Google-Grey";
import ShopifyIcon from "assets/images/Integrations/JSX/Logo-Shopify";
import ShopifyIconGrey from "assets/images/Integrations/JSX/Logo-Shopify-Grey";
import CustomMetricsIcon from "assets/images/Integrations/JSX/Logo-CustomMetrics";
import CustomMetricsIconGrey from "assets/images/Integrations/JSX/Logo-CustomMetrics-Grey";
import IconSlack from "assets/images/Integrations/JSX/Logo-Slack";
import IconGoogleBusinessProfileGrey from "assets/images/Integrations/JSX/Logo-GoogleBusinessProfileGrey";
import IconGoogleBusinessProfile from "assets/images/Integrations/JSX/Logo-GoogleBusinessProfile";
import { ReactComponent as CampaignGroupIcon } from "assets/images/icons/Icon-CampaignGroup.svg";
import CampaignGroupIconGrey from "assets/images/icons/JSX/Icon-CampaignGroupGrey";
import { ReactComponent as BudgetGroupIcon } from "assets/images/icons/Icon-BudgetGroup.svg";
import { ReactComponent as AnalyticsV4Icon } from "assets/images/Integrations/Icon-GoogleAnalytics-v4.svg";
import { ReactComponent as AnalyticsV4IconGrey } from "assets/images/Integrations/Icon-GoogleAnalytics-v4-grey.svg";
import IconBenchmark from "assets/images/icons/JSX/Icon-Benchmark";
import IconChatGPT from "assets/images/icons/JSX/Icon-ChatGPT";
import { ReactComponent as AlertsIcon } from "assets/images/Menu/Icon-AlertsSummary.svg";
import { BIGQUERY_METRIC_PREFIX } from "lib/bigQuery/helpers";
import IconStackAdapt from "assets/images/Integrations/JSX/Logo-StackAdapt";
import { ReactComponent as IconStackAdaptGrey } from "assets/images/Integrations/Icon-StackAdapt-Grey.svg";
import { ReactComponent as IconMailchimp } from "assets/images/Integrations/Icon-Mailchimp.svg";
import { ReactComponent as IconMailchimpGrey } from "assets/images/Integrations/Icon-Mailchimp-Grey.svg";
import { ReactComponent as IconInstagram } from "assets/images/Integrations/Icon-Instagram.svg";
import { ReactComponent as IconInstagramGrey } from "assets/images/Integrations/Icon-Instagram-Grey.svg";

export const Icon = ({ platform = "", ...otherProps }) => {
    if (platform.startsWith(BIGQUERY_METRIC_PREFIX)) {
        return <IconBigQuery {...otherProps} />;
    }
    if (platform.includes("analyticsV4")) {
        return <AnalyticsV4Icon {...otherProps} />;
    }
    if (platform.includes("adwords")) {
        return <IconGoogleAds {...otherProps} />;
    }
    if (platform.startsWith("campaignGroup")) {
        return <CampaignGroupIcon {...otherProps} />;
    }
    if (platform.startsWith("benchmark")) {
        return <IconBenchmark {...otherProps} />;
    }
    if (platform.includes("alerts")) {
        return <AlertsIcon />;
    }
    switch (platform) {
        case "facebook":
        case "organicFacebook":
            return <IconFacebook {...otherProps} />;
        case "linkedin":
            return <IconLinkedin {...otherProps} />;
        case "tiktok":
            return <IconTikTok {...otherProps} />;
        case "shopify":
            return <ShopifyIcon {...otherProps} />;
        case "bing":
            return <IconBing {...otherProps} />;
        case "googleBusinessProfile":
            return <IconGoogleBusinessProfile {...otherProps} />;
        case "googleSearch":
        case "searchConsole":
            return <SearchConsoleIcon {...otherProps} />;
        case "slack":
            return <IconSlack {...otherProps} />;
        case "custom":
            return <CustomMetricsIcon {...otherProps} />;
        case "pinterest":
            return <IconPinterest {...otherProps} />;
        case "maropost":
            return <IconMaropost {...otherProps} />;
        case "klaviyo":
            return <IconKlaviyo {...otherProps} />;
        case "chatgpt":
            return <IconChatGPT {...otherProps} />;
        case "bigQuery":
            return <IconBigQuery {...otherProps} />;
        case "budgetGroup":
            return <BudgetGroupIcon {...otherProps} />;
        case "stackAdapt":
            return <IconStackAdapt {...otherProps} />;
        case "mailchimp":
            return <IconMailchimp {...otherProps} />;
        case "organicInstagram":
            return <IconInstagram {...otherProps} />;
        default:
            return null;
    }
};

export const GreyIcon = ({ platform = "" }) => {
    if (platform.startsWith(BIGQUERY_METRIC_PREFIX)) {
        return <IconBigQueryGrey />;
    }
    if (platform.includes("analyticsV4")) {
        return <AnalyticsV4IconGrey />;
    }
    if (platform.includes("adwords")) {
        return <IconGoogleAdsGrey />;
    }
    if (platform.startsWith("campaignGroup")) {
        return <CampaignGroupIconGrey />;
    }
    switch (platform) {
        case "adwords":
            return <IconGoogleAdsGrey />;
        case "facebook":
        case "organicFacebook":
            return <IconFacebookGrey />;
        case "tiktok":
            return <IconTikTokGrey />;
        case "linkedin":
            return <IconLinkedinGrey />;
        case "shopify":
            return <ShopifyIconGrey />;
        case "bing":
            return <IconBingGrey />;
        case "custom":
            return <CustomMetricsIconGrey />;
        case "googleBusinessProfile":
            return <IconGoogleBusinessProfileGrey />;
        case "seo":
        case "googleSearch":
        case "searchConsole":
            return <SearchConsoleIconGrey />;
        case "pinterest":
            return <IconPinterestGrey />;
        case "maropost":
            return <IconMaropostGrey />;
        case "klaviyo":
            return <IconKlaviyoGrey />;
        case "bigQuery":
            return <IconBigQueryGrey />;
        case "stackAdapt":
            return <IconStackAdaptGrey />;
        case "mailchimp":
            return <IconMailchimpGrey />;
        case "organicInstagram":
            return <IconInstagramGrey />;
        default:
            return null;
    }
};

const PlatformIcon = ({ platform, className, backgroundColor = "rgba(226, 233, 241, 0.3)", isGrey = false }) => {
    return (
        <div className={`${style.platformIcon} ${className ? className : ""}`} style={{ backgroundColor }}>
            {isGrey ? <GreyIcon platform={platform} /> : <Icon platform={platform} />}
        </div>
    );
};

export default PlatformIcon;
