import { getChannelMetrics, getAllAdwordsMetrics } from "lib/reports/campaigns/constants";
import { KpiAPI } from "lib/apiKpi";
import { prepareDateRange } from "./helpers";
import { MAILCHIMP_KPI_METRICS } from "lib/mailchimp/metrics";

const getValidMetrics = (platform) => {
    if (platform === "adwords") {
        return getAllAdwordsMetrics();
    }

    if (platform === "mailchimp") {
        return Object.keys(MAILCHIMP_KPI_METRICS);
    }

    return getChannelMetrics(platform).map((metric) => `${platform}-${metric}`) || [];
};

export function getPlatformCampaignsReport({ platform, dateRange, accountId, abortController }) {
    if (!platform) {
        return {};
    }

    const [{ start: startDate, end: endDate }, { start: compareToStartDate, end: compareToEndDate }] = prepareDateRange(
        dateRange,
    );

    return KpiAPI({
        method: "GET",
        url: `/account/${accountId}`,
        query: {
            date: endDate,
            startDate,
            endDate,
            compareToStartDate,
            compareToEndDate,
            metrics: JSON.stringify({
                metrics: getValidMetrics(platform),
            }),
            compareTo: `${compareToStartDate}-${compareToEndDate}`,
            includeCampaignData: true,
        },
        abortController,
    }).then(({ data }) => data);
}
