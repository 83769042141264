import React from "react";
import { GreyIcon, Icon } from "components/Icon/PlatformIcon";

export default {
    analyticsV4: {
        name: "Google Analytics 4",
        icon: () => <Icon platform="analyticsV4" />,
        iconGrey: () => <GreyIcon platform="analyticsV4" />,
    },
    adwords: {
        name: "Google Ads",
        icon: () => <Icon platform="adwords" />,
        iconGrey: () => <GreyIcon platform="adwords" />,
    },
    facebook: {
        name: "Meta Ads",
        icon: () => <Icon platform="facebook" />,
        iconGrey: () => <GreyIcon platform="facebook" />,
    },
    tiktok: {
        name: "TikTok Ads",
        icon: () => <Icon platform="tiktok" />,
        iconGrey: () => <GreyIcon platform="tiktok" />,
    },
    pinterest: {
        name: "Pinterest Ads",
        icon: () => <Icon platform="pinterest" />,
        iconGrey: () => <GreyIcon platform="pinterest" />,
    },
    linkedin: {
        name: "LinkedIn Ads",
        icon: () => <Icon platform="linkedin" />,
        iconGrey: () => <GreyIcon platform="linkedin" />,
    },
    bing: {
        name: "Microsoft Advertising",
        icon: () => <Icon platform="bing" />,
        iconGrey: () => <GreyIcon platform="bing" />,
    },
    shopify: {
        name: "Shopify",
        icon: () => <Icon platform="shopify" />,
        iconGrey: () => <GreyIcon platform="shopify" />,
    },
    maropost: {
        name: "Maropost",
        icon: () => <Icon platform="maropost" />,
        iconGrey: () => <GreyIcon platform="maropost" />,
    },
    klaviyo: {
        name: "Klaviyo",
        icon: () => <Icon platform="klaviyo" />,
        iconGrey: () => <GreyIcon platform="klaviyo" />,
    },
    googleBusinessProfile: {
        name: "Google Business Profile",
        icon: () => <Icon platform="googleBusinessProfile" />,
        iconGrey: () => <GreyIcon platform="googleBusinessProfile" />,
    },
    custom: {
        name: "Custom Metric",
        icon: () => <Icon platform="custom" />,
        iconGrey: () => <GreyIcon platform="custom" />,
    },
    campaignGroup: {
        name: "Campaign Groups",
        icon: () => <Icon platform="campaignGroup" />,
        iconGrey: () => <GreyIcon platform="campaignGroup" />,
    },
    bigQuery: {
        name: "BigQuery",
        icon: () => <Icon platform="bigQuery" />,
        iconGrey: () => <GreyIcon platform="bigQuery" />,
    },
    searchConsole: {
        name: "Google Search Console",
        icon: () => <Icon platform="searchConsole" />,
        iconGrey: () => <GreyIcon platform="searchConsole" />,
    },
    organicFacebook: {
        name: "Organic Facebook",
        icon: () => <Icon platform="organicFacebook" />,
        iconGrey: () => <GreyIcon platform="organicFacebook" />,
    },
    stackAdapt: {
        name: "StackAdapt",
        icon: () => <Icon platform="stackAdapt" />,
        iconGrey: () => <GreyIcon platform="stackAdapt" />,
    },
    mailchimp: {
        name: "Mailchimp",
        icon: () => <Icon platform="mailchimp" />,
        iconGrey: () => <GreyIcon platform="mailchimp" />,
    },
    organicInstagram: {
        name: "Organic Instagram",
        icon: () => <Icon platform="organicInstagram" />,
        iconGrey: () => <GreyIcon platform="organicInstagram" />,
    },
};
