import types from "./types";
import { getPlatformCampaignsReport } from "lib/reports/campaigns/api";

const getMailchimpData = ({ dateRange, accountId, abortController }) => (dispatch) => {
    return dispatch({
        type: types.MAILCHIMP_REPORT,
        promise: getPlatformCampaignsReport({
            platform: "mailchimp",
            dateRange,
            accountId,
            abortController,
        }),
    });
};

export default {
    getMailchimpData,
};
