import { getMetricKey } from "lib/utility";
import { get, isEmpty, omit, pick } from "lodash";
import { COMPARISONS, getChannelMetrics } from "lib/reports/campaigns/constants";
import { ADWORDS_NETWORKS } from "lib/adwords/networks";
import moment from "moment";

const formatMetricValue = ({ noneFormatMetrics = [], key, value }) => {
    if (noneFormatMetrics.includes(key)) {
        return value;
    }
    return isFinite(value) ? Math.round(value * 100) / 100 : "N/A";
};

export const formatCampaigns = ({ noneFormatMetrics = [], campaigns = [] }) =>
    campaigns.map((campaign) => {
        return Object.keys(campaign).reduce((newCampaign, key) => {
            return { ...newCampaign, [key]: formatMetricValue({ noneFormatMetrics, key, value: campaign[key] }) };
        }, {});
    });

export const getReportValues = ({ metricKeys = [], actualData = {}, previousData = {} }) => {
    if (isEmpty(actualData)) {
        return [];
    }

    return metricKeys.map((metricKey) => {
        return {
            metric: metricKey,
            name: metricKey,
            actuals: actualData[metricKey],
            previous: previousData[metricKey],
        };
    });
};

const getCampaignMetricValue = ({ campaignId, metricData = {}, compare = "" }) => {
    if (isEmpty(metricData) || !campaignId) {
        return 0;
    }

    if (!compare) {
        return get(metricData, ["campaignAggregatedData", campaignId]) || 0;
    }

    if (compare === COMPARISONS.FORECAST) {
        return get(metricData, ["forecastAggregatedCampaignData", campaignId]) || 0;
    }

    return get(metricData, ["campaignAggregatedComparisonData", campaignId]) || 0;
};

const getCampaignInfo = (campaignData = {}) => {
    const { campaignId, campaign, integrationId = "", network, audience, recipients } = campaignData;

    return {
        campaignId,
        campaign,
        integrationId,
        ...(network ? { network } : {}),
        ...(audience ? { audience, recipients } : {}),
    };
};

const aggregateCampaigns = ({ listCampaigns = [], metricsData = {}, compare }) => {
    let actualCampaigns = [];
    let compareCampaigns = [];

    listCampaigns.forEach((campaignData = {}) => {
        const { campaignId } = campaignData;

        const metrics = Object.keys(metricsData).reduce(
            (cache, metric) => {
                const metricKey = getMetricKey(metric);
                return {
                    actualMetrics: {
                        ...cache.actualMetrics,
                        [metricKey]: getCampaignMetricValue({
                            campaignId,
                            metricData: metricsData[metric] || {},
                        }),
                    },
                    compareMetrics: compare
                        ? {
                              ...cache.compareMetrics,
                              [metricKey]: getCampaignMetricValue({
                                  campaignId,
                                  metricData: metricsData[metric] || {},
                                  compare,
                              }),
                          }
                        : {},
                };
            },
            { actualMetrics: {}, compareMetrics: {} },
        );

        const campaignInfo = getCampaignInfo(campaignData);

        actualCampaigns.push({ ...campaignInfo, ...metrics.actualMetrics });

        if (compare) {
            compareCampaigns.push({ ...campaignInfo, ...metrics.compareMetrics });
        }
    });

    return { actualCampaigns, compareCampaigns };
};

const getCampaigns = (metricsData = {}) => {
    if (isEmpty(metricsData)) {
        return [];
    }

    let allCampaigns = {};

    Object.keys(metricsData).forEach((metric) => {
        const metricKey = getMetricKey(metric);
        const { campaignData = [] } = metricsData[metric] || {};
        campaignData.forEach((campaignDailyData) => {
            const { campaignId = "" } = campaignDailyData || {};

            if (allCampaigns[campaignId]) {
                return;
            }

            allCampaigns[campaignId] = omit(campaignDailyData, ["date", metricKey]);
        });
    });

    return Object.values(allCampaigns);
};

export const filterCampaigns = ({ data = [], network = "", campaignId }) => {
    if (campaignId) {
        return data.filter((item) => item.campaignId === campaignId);
    }
    if (network) {
        return data.filter((item) => {
            const { network: dataNetwork, campaignId } = item;
            const hasCorrectNetwork = dataNetwork
                ? dataNetwork.toString().includes(ADWORDS_NETWORKS[network].friendlyName)
                : false;
            return hasCorrectNetwork && campaignId;
        });
    }
    return data.filter((item) => item.campaignId);
};

export const getMetrics = ({ platforms = [], metrics = [], comparison = "" }) => {
    return platforms.reduce((cache, channel) => {
        const validMetrics = isEmpty(metrics) ? getChannelMetrics(channel) : metrics;
        const channelMetrics = validMetrics.map((metric) => `${channel}-${metric}`);
        return [...cache, ...channelMetrics];
    }, []);
};

export const getCampaignsData = ({ reportData = {}, platform = "", network = "", campaignId = "", compare = "" }) => {
    if (isEmpty(reportData)) {
        return {};
    }

    const metricKeys = getMetrics({ platforms: [platform] });

    const metricsData = pick(reportData, metricKeys) || {};
    const campaigns = getCampaigns(metricsData);
    const listCampaigns = filterCampaigns({ data: campaigns, network, campaignId });
    const { actualCampaigns = [], compareCampaigns = [] } =
        aggregateCampaigns({ listCampaigns, metricsData, compare }) || {};

    return { actual: actualCampaigns, compare: compareCampaigns };
};

export const getTotalsData = (reportData = {}) =>
    Object.keys(reportData).reduce(
        (cache, metric) => {
            const metricKey = getMetricKey(metric);
            return {
                actual: {
                    ...cache.actual,
                    [metricKey]: reportData[metric].actual,
                },
                previous: {
                    ...cache.previous,
                    [metricKey]: reportData[metric].oneMonth,
                },
            };
        },
        { actual: {}, previous: {} },
    );

const arrayReportFields = ["campaignData", "campaignDataComparison"];
const objectReportFields = [
    "campaignAggregatedComparisonData",
    "campaignAggregatedData",
    "expectedRangeCampaignData",
    "expectedRangeAggregatedCampaignData",
    "forecastCampaignData",
    "forecastAggregatedCampaignData",
];

const formatReportDataField = ({ campaignGroupsIds, reportData, platform, metric, fieldName }) => {
    return Object.keys(campaignGroupsIds).reduce((cache, platform) => {
        const listFieldData = reportData[`${platform}-${metric}`]?.[fieldName];
        const isArrayField = arrayReportFields.includes(fieldName);

        if (isEmpty(listFieldData)) {
            return isArrayField ? [...cache] : { ...cache };
        }

        if (isArrayField) {
            const listFieldDataFormatted = listFieldData
                ?.filter(({ campaignId }) => campaignGroupsIds[platform].includes(campaignId))
                .map((campaign) => {
                    return { ...campaign, campaignGroupPlatform: platform };
                });

            return [...cache, ...(listFieldDataFormatted || [])];
        }

        const fieldDataFormatted = Object.keys(listFieldData)?.reduce((cache, campaignId) => {
            if (!campaignGroupsIds[platform].includes(campaignId)) {
                return {
                    ...cache,
                };
            }
            return {
                ...cache,
                [campaignId]: listFieldData[campaignId],
            };
        }, {});

        return { ...cache, ...fieldDataFormatted };
    }, []);
};

export const formatReportData = ({ reportData = {}, platform = "", campaignGroupsIds = [] }) => {
    return Object.keys(reportData).reduce((cache, reportKey) => {
        if (!reportKey.startsWith("campaignGroup") || !reportKey.startsWith(`${platform}-`)) {
            return {
                ...cache,
                [reportKey]: reportData[reportKey],
            };
        }

        const [, metric] = reportKey.split("-");
        const fieldFormatted = {
            ...reportData[reportKey],
            ...[...arrayReportFields, ...objectReportFields].reduce((cache, fieldName) => {
                return {
                    ...cache,
                    [fieldName]: formatReportDataField({
                        campaignGroupsIds,
                        reportData,
                        platform,
                        metric,
                        fieldName,
                    }),
                };
            }, {}),
        };

        return {
            ...cache,
            [reportKey]: fieldFormatted,
        };
    }, {});
};

export const filterAdwordsReportData = ({ reportData = {}, network = "" }) => {
    if (isEmpty(reportData)) {
        return {};
    }

    const validNetwork = network || "adwords";
    const metricKeys = Object.keys(reportData).filter((metricKey) => metricKey.startsWith(`${validNetwork}-`));
    return pick(reportData, metricKeys);
};

export const prepareDateRange = (dateRange = []) => {
    return dateRange
        .filter((obj) => {
            return obj.hasOwnProperty("start") && obj.hasOwnProperty("end");
        })
        .map(({ start, end }) => {
            return {
                start: moment(start).format("YYYY-MM-DD"),
                end: moment(end).format("YYYY-MM-DD"),
            };
        });
};
