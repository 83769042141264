import axios from "axios";
import { request } from "./api";
import { store } from "../_redux";

const { REACT_APP_KPI_API_DOMAIN } = process.env;

var api = axios.create({
    baseURL: REACT_APP_KPI_API_DOMAIN,
    timeout: 1000000,
});

export const getSelectedCampaignRegex = (state) => {
    const dashboardId = state.sites.selectedSite.selectedDashboard || {};
    const dashboards = state.sites.selectedSite.dashboards || {};

    if (dashboardId === "default" || Object.keys(dashboards).indexOf(dashboardId) === -1) {
        return null;
    }

    const campaignRegex = dashboards[dashboardId].campaignRegex;
    return !campaignRegex ? null : campaignRegex;
};

export const userRequest = ({ accountId: optionalAccountId } = {}) => {
    const accountId = optionalAccountId || store.getState().sites.selectedSite._id;
    if (!accountId) return Promise.reject("account id null");

    const {
        sites: { selectedSite: { integrations: externalIds = {}, connections } = {} } = {},
        user: { user: { sub: userId, app_metadata: { parent: parentUserId = false } = {} } } = {},
    } = store.getState();

    const scopes = {
        bing: {
            scope: "bing",
        },
        facebook: {
            scope: "facebook",
        },
        adwords: {
            scope: "adwords",
        },
        linkedin: {
            scope: "linkedin",
        },
        shopify: {
            scope: "shopify",
        },
        tiktok: {
            scope: "tiktok",
        },
    };

    const availableScope = new Array(
        ...new Set(
            Object.keys(scopes)
                .filter((scope) => {
                    return externalIds[scope];
                })
                .map((scope) => {
                    return scopes[scope].scope;
                }),
        ),
    );

    if (externalIds)
        return Promise.resolve({
            ...externalIds,
            accountId,
            connections,
            availableScope,
            userId,
            parentUserId,
        });
};

/**
 * Request Wrapper for KPI API
 *
 * @param {object} data
 * @param {string} data.url - URL of request
 * @param {object} data.data - Data of request
 * @param {string} data.method - Method
 * @param {string} data.query - Request query
 * @param {array} data.headers - Additional Headers
 * @param {?} data.file - File to end up
 * @param {boolean} data.bypassCache
 * @param {bolean} data.cancelSubsequentRequests
 *
 * @return {Promise}
 */

export const KpiAPI = (data) => {
    const requestData = request(api);

    // Ensure headers object exists
    if (!data.headers) {
        data.headers = {};
    }

    // Add your custom header
    data.headers["X-Request-Source"] = "app";

    return requestData(data);
};
