import createReducer from "lib/createReducer";
import clearReducer from "lib/clearReducer";
import types from "./types";
import moment from "moment";
import { handle } from "redux-pack";
import { COMPARISONS } from "features/Analysis/helpers";
import { get, merge } from "lodash";

export const defaultState = {
    settings: {
        start: moment()
            .subtract(30, "days")
            .format("YYYY-MM-DD"),
        end: moment()
            .subtract(1, "days")
            .format("YYYY-MM-DD"),
        compareToEnd: null,
        compareToStart: null,
        compare: COMPARISONS.NONE,
    },
    reportData: {},
    paidPlatformsReportData: {},
    campaignGroupsReportData: {},
    ga4ReportData: {},
    emailPlatformsReportData: {},
    shopifyReportData: {},
    mailchimpReportData: {},
    breadcrumb: {
        platform: "",
        network: "",
        campaign: "",
        campaignNetwork: [],
        adGroup: "",
        ad: "",
        campaignGroupPlatform: "",
    },
    campaigns: [],
    adGroupReports: {},
    adGroupsInfo: [],
    adGroupsInfoLoading: false,
    adGroupsInfoError: false,
    adReports: {},
    adsInfo: [],
    adsInfoError: true,
    adsInfoLoading: false,
    adGroups: [],
    ads: [],
    reportLoading: false,
    campaignGroupsInfo: {},
    campaignGroupsInfoLoading: false,
    campaignGroupsInfoError: false,
    campaignsFilter: "all",
};

export default clearReducer(defaultState)(
    createReducer(defaultState, {
        [types.REPORT_CLEAR]: (state) => {
            return { ...defaultState, reportLoading: state.reportLoading };
        },
        [types.REPORT_SETTINGS]: (state, action) => {
            const oldSettings = state.settings;
            return {
                ...state,
                settings: { ...oldSettings, ...action.payload },
            };
        },
        [types.REPORT_BREADCRUMB]: (state, action) => {
            const oldBreadcrumb = state.breadcrumb;
            return {
                ...state,
                breadcrumb: { ...oldBreadcrumb, ...action.payload },
            };
        },
        [types.SET_CAMPAIGNS]: (state, action) => {
            return {
                ...state,
                campaigns: action.payload || [],
            };
        },
        [types.GET_REPORT_DATA]: (state, action) => {
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    reportLoading: true,
                }),
                success: (prevState) => {
                    const isCancelledReq = get(action, ["payload", "isCancelledReq"]);
                    const reportData = isCancelledReq ? prevState.reportData : { ...action.payload };
                    return {
                        ...prevState,
                        reportData,
                        adGroupReportsLoadingError: false,
                        reportLoading: isCancelledReq ? true : false,
                    };
                },
                failure: (prevState) => ({
                    ...prevState,
                    reportLoadingError: true,
                    reportLoading: false,
                }),
            });
        },
        [types.GET_PAID_PLATFORMS_REPORT_DATA]: (state, action) => {
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    paidPlatformsReportLoading: true,
                }),
                success: (prevState) => {
                    const isCancelledReq = get(action, ["payload", "isCancelledReq"]);
                    const paidPlatformsReportData = isCancelledReq
                        ? prevState.paidPlatformsReportData
                        : { ...action.payload };
                    return {
                        ...prevState,
                        paidPlatformsReportData,
                        paidPlatformsReportLoading: isCancelledReq ? true : false,
                        adGroupReportsLoadingError: false,
                    };
                },
                failure: (prevState) => ({
                    ...prevState,
                    paidPlatformsReportLoadingError: true,
                    paidPlatformsReportLoading: false,
                }),
            });
        },
        [types.GET_CAMPAIGN_GROUPS_REPORT_DATA]: (state, action) => {
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    campaignGroupsReportLoading: true,
                }),
                success: (prevState) => {
                    const isCancelledReq = get(action, ["payload", "isCancelledReq"]);
                    const campaignGroupsReportData = isCancelledReq
                        ? prevState.campaignGroupsReportData
                        : { ...action.payload };
                    return {
                        ...prevState,
                        campaignGroupsReportData,
                        campaignGroupsReportLoading: isCancelledReq ? true : false,
                    };
                },
                failure: (prevState) => ({
                    ...prevState,
                    campaignGroupsReportLoadingError: true,
                    campaignGroupsReportLoading: false,
                }),
            });
        },
        [types.GET_GA4_REPORT_DATA]: (state, action) => {
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    ga4ReportLoading: true,
                }),
                success: (prevState) => {
                    const isCancelledReq = get(action, ["payload", "isCancelledReq"]);
                    const ga4ReportData = isCancelledReq ? prevState.ga4ReportData : { ...action.payload };
                    return {
                        ...prevState,
                        ga4ReportData,
                        ga4ReportLoading: isCancelledReq ? true : false,
                    };
                },
                failure: (prevState) => ({
                    ...prevState,
                    ga4ReportLoadingError: true,
                    ga4ReportLoading: false,
                }),
            });
        },
        [types.GET_EMAIL_PLATFORMS_REPORT_DATA]: (state, action) => {
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    emailPlatformsReportLoading: true,
                }),
                success: (prevState) => {
                    const isCancelledReq = get(action, ["payload", "isCancelledReq"]);
                    const emailPlatformsReportData = isCancelledReq
                        ? prevState.emailPlatformsReportData
                        : { ...action.payload };
                    return {
                        ...prevState,
                        emailPlatformsReportData,
                        emailPlatformsReportLoading: isCancelledReq ? true : false,
                    };
                },
                failure: (prevState) => ({
                    ...prevState,
                    emailPlatformsReportLoadingError: true,
                    emailPlatformsReportLoading: false,
                }),
            });
        },
        [types.GET_MAILCHIMP_REPORT_DATA]: (state, action) => {
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    mailchimpReportLoading: true,
                }),
                success: (prevState) => {
                    const isCancelledReq = get(action, ["payload", "isCancelledReq"]);
                    const mailchimpReportData = isCancelledReq ? prevState.mailchimpReportData : { ...action.payload };
                    return {
                        ...prevState,
                        mailchimpReportData,
                        mailchimpReportLoading: isCancelledReq ? true : false,
                    };
                },
                failure: (prevState) => ({
                    ...prevState,
                    mailchimpReportLoadingError: true,
                    mailchimpReportLoading: false,
                }),
            });
        },
        [types.GET_SHOPIFY_REPORT_DATA]: (state, action) => {
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    shopifyReportLoading: true,
                }),
                success: (prevState) => {
                    const isCancelledReq = get(action, ["payload", "isCancelledReq"]);
                    const shopifyReportData = isCancelledReq ? prevState.shopifyReportData : { ...action.payload };
                    return {
                        ...prevState,
                        shopifyReportData,
                        shopifyReportLoading: isCancelledReq ? true : false,
                    };
                },
                failure: (prevState) => ({
                    ...prevState,
                    shopifyReportLoadingError: true,
                    shopifyReportLoading: false,
                }),
            });
        },
        [types.GET_AD_GROUP_REPORTS]: (state, action) => {
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    adGroupReportsLoading: true,
                }),
                success: (prevState) => {
                    const isCancelledReq = get(action, ["payload", "isCancelledReq"]);
                    const { meta: { platform } = {}, payload } = action;
                    const adGroupReports = isCancelledReq
                        ? prevState.adGroupReports
                        : { ...prevState.adGroupReports, [platform]: payload };

                    return {
                        ...prevState,
                        adGroupReports,
                        adGroupReportsLoadingError: false,
                        adGroupReportsLoading: isCancelledReq ? true : false,
                    };
                },
                failure: (prevState) => ({
                    ...prevState,
                    adGroupReportsLoadingError: true,
                    adGroupReportsLoading: false,
                }),
            });
        },
        [types.GET_AD_GROUPS_INFO]: (state, action) => {
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    adGroupsInfoLoading: true,
                }),
                success: (prevState) => {
                    const isCancelledReq = get(action, ["payload", "isCancelledReq"]);
                    const { meta: { platform } = {}, payload } = action;
                    const adGroupsInfo = isCancelledReq ? [] : payload[platform];

                    return {
                        ...prevState,
                        adGroupsInfo,
                        adGroupsInfoError: false,
                        adGroupsInfoLoading: isCancelledReq ? true : false,
                    };
                },
                failure: (prevState) => ({
                    ...prevState,
                    adGroupsInfo: [],
                    adGroupsInfoError: true,
                    adGroupsInfoLoading: false,
                }),
            });
        },
        [types.UPDATE_AD_GROUP]: (state, action) =>
            handle(state, action, {
                success: (prevState) => {
                    const { adGroupId, data } = action.meta || {};
                    const { adGroupsInfo = [] } = prevState || {};
                    const updated = adGroupsInfo.map((adGroup) => {
                        const { id } = adGroup || {};
                        return id === adGroupId ? { ...adGroup, ...data } : adGroup;
                    });

                    return {
                        ...prevState,
                        adGroupsInfo: [...updated],
                    };
                },
            }),
        [types.GET_AD_REPORTS]: (state, action) => {
            const { meta: { platform } = {}, payload } = action;
            const platformLoadingKey = `${platform}AdReportsLoading`;

            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    [platformLoadingKey]: true,
                }),
                success: (prevState) => {
                    const isCancelledReq = get(action, ["payload", "isCancelledReq"]);
                    const adReports = isCancelledReq
                        ? prevState.adReports
                        : { ...prevState.adReports, [platform]: payload };
                    return {
                        ...prevState,
                        adReports,
                        adReportsLoadingError: false,
                        [platformLoadingKey]: isCancelledReq ? true : false,
                    };
                },
                failure: (prevState) => ({
                    ...prevState,
                    adReportsLoadingError: true,
                    [platformLoadingKey]: false,
                }),
            });
        },
        [types.GET_ADS_INFO]: (state, action) => {
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    adsInfoLoading: true,
                }),
                success: (prevState) => {
                    const isCancelledReq = get(action, ["payload", "isCancelledReq"]);
                    const { meta: { platform } = {}, payload } = action;
                    const adsInfo = isCancelledReq ? [] : payload[platform];

                    return {
                        ...prevState,
                        adsInfo,
                        adsInfoError: false,
                        adsInfoLoading: isCancelledReq ? true : false,
                    };
                },
                failure: (prevState) => ({
                    ...prevState,
                    adsInfo: [],
                    adsInfoError: true,
                    adsInfoLoading: false,
                }),
            });
        },
        [types.UPDATE_AD]: (state, action) =>
            handle(state, action, {
                success: (prevState) => {
                    const { adId, data } = action.meta || {};
                    const { adsInfo = [] } = prevState || {};
                    const updated = adsInfo.map((ad) => {
                        const { id } = ad || {};
                        return id === adId ? { ...ad, ...data } : ad;
                    });

                    return {
                        ...prevState,
                        adsInfo: [...updated],
                    };
                },
            }),
        [types.SET_ADGROUPS]: (state, action) => {
            return {
                ...state,
                adGroups: action.payload || [],
            };
        },
        [types.SET_ADS]: (state, action) => {
            return {
                ...state,
                ads: action.payload || [],
            };
        },
        [types.RESET_AD_REPORTS]: (state, action) => {
            return {
                ...state,
                adGroupReports: {},
                adReports: {},
                adGroups: [],
                ads: [],
                adGroupsInfo: [],
                adsInfo: [],
            };
        },
        [types.FETCH_LISTS_ADGROUPS]: (state, action) =>
            handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    adGroupsLoading: true,
                }),
                success: (prevState) => {
                    const { data } = action.payload;
                    const dataFormatted = data.isCancelledReq ? {} : data;

                    return {
                        ...prevState,
                        adGroupsLoading: false,
                        adGroups: {
                            ...prevState.adGroups,
                            ...Object.keys(dataFormatted).reduce(
                                (cache, key) => ({
                                    ...cache,
                                    [key]: dataFormatted[key].map(({ name, id } = {}) => ({
                                        label: `${name} - ${id}`,
                                        value: id,
                                    })),
                                }),
                                {},
                            ),
                        },
                    };
                },
                finish: (prevState) => ({
                    ...prevState,
                    adGroupsLoading: false,
                }),
            }),
        [types.SET_CAMPAIGNS_FILTER]: (state, action) => {
            return {
                ...state,
                campaignsFilter: action.payload || [],
            };
        },
        [types.GET_CAMPAIGN_GROUPS_INFO]: (state, action) =>
            handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    campaignGroupsInfoLoading: true,
                    campaignGroupsInfoError: false,
                }),
                success: (prevState) => ({
                    ...prevState,
                    campaignGroupsInfo: action.payload,
                    campaignGroupsInfoLoading: false,
                }),
                failure: (prevState) => ({
                    ...prevState,
                    campaignGroupsInfoLoading: false,
                    campaignGroupsInfoError: true,
                }),
            }),
        [types.UPDATE_PLATFORM_CAMPAIGN_ANALYSIS]: (state, action) =>
            handle(state, action, {
                success: (prevState) => {
                    const { campaignId, platform, data } = action.meta || {};
                    return merge({}, prevState, {
                        campaignGroupsInfo: {
                            [platform]: {
                                [campaignId]: data,
                            },
                        },
                    });
                },
            }),
    }),
);
