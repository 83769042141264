import createReducer from "lib/createReducer";
import clearReducer from "lib/clearReducer";
import types from "./types";
import { handle } from "redux-pack";
import { get } from "lodash";

const defaultState = {
    report: {},
    organicInstagramLoading: undefined,
    demographics: {},
    organicInstagramDemographicsLoading: undefined,
    topPosts: {},
    organicInstagramTopPostsLoading: undefined,
};

export default clearReducer(defaultState)(
    createReducer(defaultState, {
        [types.ORGANIC_INSTAGRAM_INSIGHT_REPORT]: (state, action) =>
            handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    organicInstagramLoading: true,
                }),
                finish: (prevState) => ({
                    ...prevState,
                    organicInstagramLoading: false,
                }),
                success: (prevState) => {
                    const isCancelledReq = get(action, ["payload", "isCancelledReq"]);
                    const { integrationId } = action.meta || {};

                    return {
                        ...prevState,
                        report: { ...prevState.report, [integrationId]: isCancelledReq ? [] : action.payload },
                    };
                },
            }),
        [types.ORGANIC_INSTAGRAM_DEMOGRAPHICS]: (state, action) =>
            handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    organicInstagramDemographicsLoading: true,
                }),
                finish: (prevState) => ({
                    ...prevState,
                    organicInstagramDemographicsLoading: false,
                }),
                success: (prevState) => {
                    const isCancelledReq = get(action, ["payload", "isCancelledReq"]);
                    const { integrationId } = action.meta || {};

                    return {
                        ...prevState,
                        demographics: {
                            ...prevState.demographics,
                            [integrationId]: isCancelledReq ? [] : action.payload,
                        },
                    };
                },
            }),
            [types.ORGANIC_INSTAGRAM_TOP_POSTS]: (state, action) =>
                handle(state, action, {
                    start: (prevState) => ({
                        ...prevState,
                        organicInstagramTopPostsLoading: true,
                    }),
                    finish: (prevState) => ({
                        ...prevState,
                        organicInstagramTopPostsLoading: false,
                    }),
                    success: (prevState) => {
                        const isCancelledReq = get(action, ["payload", "isCancelledReq"]);
                        const { integrationId } = action.meta || {};
    
                        return {
                            ...prevState,
                            topPosts: { ...prevState.topPosts, [integrationId]: isCancelledReq ? [] : action.payload },
                        };
                    },
                }),
    }),
);
