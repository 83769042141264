import { ReactComponent as DashboardIcon } from "assets/images/icons/Icon-Dashboard.svg";
import { ReactComponent as AnalyzeIcon } from "assets/images/icons/Icon-Analyze.svg";
import { ReactComponent as ReportingIcon } from "assets/images/Menu/Icon-Reporting.svg";
import { ReactComponent as Settings } from "assets/images/Menu/Icon-Settings.svg";
import { ReactComponent as MonitorIcon } from "assets/images/Menu/Icon-Alerts.svg";
import { ReactComponent as AnalysisIcon } from "assets/images/icons/Icon-Analysis.svg";

export const DASHBOARD_PATH = { icon: DashboardIcon, name: "Dashboard", path: "overview" };
export const ANALYSIS_PATH = { icon: AnalysisIcon, name: "Analysis", path: "analysis" };

export const SIDEBAR_PATH = {
    Alerts: {
        icon: MonitorIcon,
        path: "alerts",
        children: {
            alertsSummary: { name: "Alerts Summary", path: "alerts", hideOnUser: ["reporting"] },
            pagehealth: { name: "Landing Page Monitoring", path: "pagehealth", hideOnUser: ["reporting"] },
            financials: { name: "Budget Monitoring", path: "financials", hideOnUser: ["reporting"] },
            enhancedBudgeting: {
                name: "Enhanced Budget Monitoring",
                path: "enhanced-budgeting",
                actions: "ACTION_ENHANCED_BUDGETS",
                hideOnUser: ["reporting"],
            },
            seo: {
                name: "SEO Rank Monitoring",
                path: "seo/keywords",
                actions: "ACTION_SEOPERFORMANCE",
                hideOnUser: ["reporting"],
            },
            goalMonitoring: { name: "Goal Monitoring", path: "goals", hideOnUser: ["reporting"] },
        },
    },
    Insights: {
        icon: AnalyzeIcon,
        path: "insights",
        children: {
            insights: {
                name: "Monthly Insights",
                path: "insights",
                actions: "ACTION_PLATFORMS",
                hideOnUser: ["reporting"],
            },
            anomalies: {
                name: "Anomalies",
                path: "anomalies",
                actions: "ACTION_WEEKLY_ANOMALIES",
                hideOnUser: ["reporting"],
            },
            chatGpt: {
                name: "ChatGPT",
                path: "chatGpt",
                actions: "ACTION_AUDIT_REPORT",
                hideOnUser: ["reporting"],
            },
            ppcaudit: { name: "Optimize", path: "ppcaudit", actions: "ACTION_PPCAUDIT", hideOnUser: ["reporting"] },
            auditReport: {
                name: "Audit Report",
                path: "audit-report",
                actions: "ACTION_AUDIT_REPORT",
                hideOnUser: ["reporting"],
            },
            benchmarks: {
                name: "Data Benchmarking",
                path: "benchmarks",
                actions: "ACTION_BENCHMARKS",
                hideOnUser: ["reporting"],
            },
            budgetPlanner: {
                name: "Budget Planner",
                path: "budgetPlanner",
                actions: "ACTION_PPCAUDIT",
                hideOnUser: ["reporting"],
            },
        },
    },
    Reports: {
        icon: ReportingIcon,
        actions: "ACTION_PLATFORMS",
        path: "kpi/summary",
        children: {
            presentationMaker: {
                name: "AI Presentation Builder",
                path: "kpi/presentation-maker",
                hideOnUser: ["reporting"],
            },
            reportSummary: { name: "Reporting Summary", path: "kpi/summary" },
            reportBuilder: { name: "Report Builder", path: "kpi/report-builder" },
            adwords: { name: "Google Ads", path: "kpi/google-ads", platforms: "adwords" },
            facebook: { name: "Meta Ads", path: "kpi/facebook-ads", platforms: "facebook" },
            organicFacebook: { name: "Organic Facebook", path: "kpi/organic-facebook", platforms: "organicFacebook" },
            organicInstagram: {
                name: "Organic Instagram",
                path: "kpi/organic-instagram",
                platforms: "organicInstagram",
            },
            linkedin: { name: "LinkedIn Ads", path: "kpi/linkedin-ads", platforms: "linkedin" },
            bing: { name: "Microsoft Advertising", path: "kpi/microsoft-advertising", platforms: "bing" },
            googleAnalyticsV4: {
                name: "Google Analytics V4",
                path: "kpi/google-analytics-v4",
                platforms: "analyticsV4",
            },
            searchConsole: {
                name: "Google Search Console",
                path: "kpi/google-search-console",
                platforms: "searchConsole",
            },
            googleBusinessProfile: {
                name: "Google Business Profile",
                path: "kpi/google-business-profile",
                platforms: "googleBusinessProfile",
            },
            shopify: { name: "Shopify", path: "kpi/shopify", platforms: "shopify" },
            tiktok: { name: "TikTok", path: "kpi/tiktok-ads", platforms: "tiktok" },
            pinterest: { name: "Pinterest", path: "kpi/pinterest-ads", platforms: "pinterest" },
            maropost: { name: "Maropost", path: "kpi/maropost", platforms: "maropost" },
            klaviyo: { name: "Klaviyo", path: "kpi/klaviyo", platforms: "klaviyo" },
            bigQuery: { name: "BigQuery", path: "kpi/bigQuery", platforms: "bigQuery" },
            stackAdapt: { name: "StackAdapt", path: "kpi/stackAdapt", platforms: "stackAdapt" },
            mailchimp: { name: "Mailchimp", path: "kpi/mailchimp", platforms: "mailchimp" },
        },
    },
    Settings: {
        icon: Settings,
        actions: "ACTION_ACCOUNT_SETTINGS",
        path: "settings/goals",
        children: {
            settingsGoals: { name: "Conversion Settings", path: "settings/goals" },
            settingsMargin: { name: "Agency Media Markup", path: "settings/margin" },
            settingsIntegrations: {
                name: "Integrations",
                path: "settings/integrations",
                actions: "ACTION_ACCOUNT_SETTINGS_INTEGRATIONS",
            },
            settingsCampaignGroups: {
                name: "Campaign Group",
                path: "settings/campaign-groups",
                actions: "ACTION_CAMPAIGN_GROUPS",
            },
            settingsChangeLog: { name: "Change Log", path: "settings/change-log" },
            settingsAdditional: {
                name: "Additional Settings",
                path: "settings/additional",
                actions: "ACTION_ACCOUNT_SETTINGS_INTEGRATIONS",
            },
        },
    },
};

const EXTRA_PATH = {
    user: {
        path: "user",
    },
    adminUser: {
        path: "admin/users",
    },
    adminLog: {
        path: "admin/logs",
    },
    adminData: {
        path: "admin/data-extract",
    },
    shopifyAuthorize: {
        path: "shopify/authorize",
    },
    profile: {
        path: "profile",
    },
    onboarding: {
        path: "onboarding",
    },
    extraSettings: {
        path: "settings",
    },
    alertSettings: {
        path: "alert-settings",
    },
    extraSeo: {
        path: "seo",
    },
    suggestions: {
        path: "suggestions",
    },
    segments: {
        path: "segments",
    },
    forecast: {
        path: "forecast",
    },
    placements: {
        path: "placements",
    },
    seoaudit: {
        path: "seoaudit",
    },
};

const EXPOSED_PATH = {
    page404: {
        path: "/404",
    },
    shopifyInstall: {
        path: "/shopify/install",
    },
    login: {
        path: "/login",
    },
    loginOffsite: {
        path: "/login/offsite",
    },
    googleLogin: {
        path: "/google/login",
    },
    forgotPassword: {
        path: "/forgot-password",
    },
    passwordReset: {
        path: "/password-reset",
    },
    logout: {
        path: "/logout",
    },
    shared: {
        path: "/shared",
    },
    sharedReport: {
        path: "/sharedReport",
    },
    verifyEmail: {
        path: "/verify-email",
    },
    dashboard: {
        path: "/dashboard",
    },
};

export const getPathInfo = (parentName, childrenName) => {
    const allPath = { ...SIDEBAR_PATH, extraPath: { children: EXTRA_PATH }, exposedPath: { children: EXPOSED_PATH } };
    const childrenInfo = allPath[parentName].children;

    return childrenInfo[childrenName].path;
};
