import moment from "moment";
import { grouper, getTotals, metricFriendlyName, getMetricKey } from "lib/utility";
import { formatSearchTermMetrics } from "./metrics";
import { intersection, isEmpty } from "lodash";
import { toCapitalize } from "features/AlertsPage/Alert/helpers";
import { DEFAULT_TOP_SEARCH_TERM_METRICS, SEARCH_TERM_UNCOUNTABLE_METRICS } from "./constants";

const SEARCH_TERM_UNGROUPPED_METRICS = ["searchTerm", "adGroup", "adGroupID", "campaignId", "campaign"];

export const groupSearchTerms = ({ searchTerms = [], dateRange = {}, network, adGroup, campaign }) => {
    const { start, end } = dateRange;

    const [current, previous] = searchTerms
        .filter(
            ({ network: searchTermNetwork, adGroup: searchTermAdGroup, campaign: searchTermCampaign }) =>
                (!network || searchTermNetwork === network) &&
                (!adGroup || searchTermAdGroup === adGroup) &&
                (!campaign || searchTermCampaign === campaign),
        )
        .reduce(
            (array, item) => {
                const startDate = moment(item.date);
                if (moment(startDate).isBetween(start, end, "day", "[]")) {
                    return [[...array[0], item], array[1]];
                }
                return [array[0], [...array[1], item]];
            },
            [[], []],
        );

    const currentAdjusted = current.map((item) => formatSearchTermMetrics(item));
    const previousAdjusted = previous.map((item) => formatSearchTermMetrics(item));

    return {
        actual: grouper(
            ["searchTerm", "campaignId", "adGroupID"],
            getTotals(SEARCH_TERM_UNGROUPPED_METRICS),
        )(currentAdjusted).map(formatSearchTermMetrics || []),
        compare: grouper(
            ["searchTerm", "campaignId", "adGroupID"],
            getTotals(SEARCH_TERM_UNGROUPPED_METRICS),
        )(previousAdjusted).map(formatSearchTermMetrics || []),
        totalActual: formatSearchTermMetrics(grouper([], getTotals([]))(currentAdjusted)[0] || {}),
        totalCompare: formatSearchTermMetrics(grouper([], getTotals([]))(previousAdjusted)[0] || {}),
    };
};

const getSearchTermMetrics = (selectedMetrics = []) => {
    const defaultSearchTermMetrics = [...SEARCH_TERM_UNCOUNTABLE_METRICS, ...DEFAULT_TOP_SEARCH_TERM_METRICS];

    return isEmpty(selectedMetrics)
        ? defaultSearchTermMetrics
        : intersection(defaultSearchTermMetrics, [
              ...SEARCH_TERM_UNCOUNTABLE_METRICS,
              ...selectedMetrics.map(getMetricKey),
          ]);
};

export const getSearchTermHeadCells = (selectedMetrics = []) => {
    const searchTermMetrics = getSearchTermMetrics(selectedMetrics);

    return [
        {
            id: "searchTerm",
            label: metricFriendlyName("searchTerm"),
            sortable: true,
            headPadding: "1rem 0.875rem 1rem 1.5rem",
            padding: "1.125rem 1rem 1rem 1.5rem",
            hasDivider: true,
            width: "220",
            freeze: true,
        },
        ...searchTermMetrics.map((metric) => ({
            id: metric,
            label: toCapitalize(metricFriendlyName(metric)),
            sortable: true,
            headPadding: "1rem 0.875rem 1rem 1.5rem",
            padding: "1.125rem 1rem 1rem 1.5rem",
            hasDivider: true,
            width: SEARCH_TERM_UNCOUNTABLE_METRICS.includes(metric) ? "220" : "120",
        })),
    ];
};
