import { KpiAPI } from "lib/apiKpi";

function getCampaigns({ userId, connectionId }) {
    return KpiAPI({
        method: "GET",
        url: `/mailchimp/campaigns`,
        query: {
            userId,
            connectionId,
        },
    })
        .then(({ data }) => {
            return {
                connectionId,
                campaigns: {
                    mailchimp: data,
                },
            };
        })
        .catch((error) => ({ data: { error: true } }));
}

export default {
    getCampaigns,
};
