import React, { useCallback, useEffect } from "react";
import { CardCVCElement, CardExpiryElement, CardNumberElement } from "react-stripe-elements";
import { Field } from "redux-form";
import style from "./style.module.scss";
import classNames from "classnames";
import RenderInput from "components/RenderInput";
import Button from "components/Button";
import ErrorBar from "components/AlertNotificationBar/Error";
import StripeLogo from "assets/images/icons/Logo-Stripe.svg";
import LockIcon from "assets/images/icons/Img-Secure.svg";
import CheckmarkStroke from "assets/images/Form/JSX/Icon-Check-Stoke";
import { TooltipDark } from "components/Tooltip";
import { toPrettyNumber } from "lib/metricCalculations";
import { ReactComponent as TagIcon } from "assets/images/icons/Icon-Tag.svg";
import LoadingDots from "components/Loading/ThreeDots";
import { usePrompt } from "components/Prompt/ShortAnnouncement/PromptContext";
import DropdownSelector from "features/Settings/ConversionSettings/DropdownSelector";

const required = (value) => (value ? undefined : "This field is required");

const inputStyle = {
    base: {
        fontSize: "14px",
        fontWeight: 400,
        fontFamily: '"Nunito Sans"',
        color: "#444C59",
        "::placeholder": {
            fontSize: "14px",
            fontWeight: 300,
            fontFamily: '"Nunito Sans"',
            color: "#BCCFDC",
        },
    },
};

const OptionRadioButtons = React.memo(({ plans = {}, input, setPromoDetails }) => {
    const { value, onChange } = input;

    return (
        <div className={`${style.options} ${style.optionsPlans}`}>
            <DropdownSelector
                options={Object.values(plans).map(({ plan, numberOfViews }) => {
                    return {
                        label: (
                            <div key={plan} className={style.optionDropDown}>
                                <label>Up To {numberOfViews} Client Accounts</label>
                            </div>
                        ),
                        value: plan,
                    };
                })}
                selectedValue={value}
                handleSelectedValue={({ plan }) => {
                    onChange(plan);
                    setPromoDetails({});
                }}
                type="plan"
                styles={{ marginLeft: 0 }}
            />
        </div>
    );
});

const CycleOptionRadioButtons = ({ input, toggleBilling }) => {
    const { value, onChange } = input;

    const changeCycle = useCallback(
        (cycle) => {
            toggleBilling(cycle);
            onChange(cycle);
        },
        [onChange, toggleBilling],
    );

    return (
        <div className={`${style.options} ${style.optionsCycle}`}>
            <div
                key="monthly"
                className={`${style.optionCheckbox} ${"monthly" === value && style.selected}`}
                onClick={() => {
                    changeCycle("monthly");
                }}
            >
                <span className={style.radioCircle}>
                    <CheckmarkStroke />
                </span>
                <label>Monthly Rolling</label>
            </div>

            <div
                key="yearly"
                className={`${style.optionCheckbox} ${"yearly" === value && style.selected}`}
                onClick={() => {
                    changeCycle("yearly");
                }}
            >
                <span className={style.radioCircle}>
                    <CheckmarkStroke />
                </span>
                <label>
                    Annual Rolling
                    <small>(Save 30%)</small>
                </label>
            </div>
        </div>
    );
};

export default ({
    error,
    handleSubmit,
    submitting,
    planInfo,
    selectedPlan,
    selectedPlanCycle,
    userType = "regular",
    toggleBilling,
    isOnboarding = false,
    fetchPromoDetails,
    coupon: couponInput,
    promoId,
    couponDataLoading,
    couponAllowed = false,
    appliedCoupon,
    change,
    setPromoDetails,
    couponError,
    percentOff,
    amount_off,
    existingPlan,
    existingInterval,
    numberOfViews: accountNumberOfViews,
    onCompletePayment,
} = {}) => {
    const { setPromptMessage } = usePrompt();

    const userTypekey = userType.includes("appSumo") ? "appSumoUser" : userType;
    const { [selectedPlanCycle]: plans = {} } = planInfo || {};
    const { [selectedPlan]: { originalPrice, price, saving, numberOfViews, maxSpend } = {} } = plans || {};
    const amountOff = amount_off ? amount_off : originalPrice * percentOff;

    const handlePromoCheck = async (e) => {
        e.preventDefault();
        await fetchPromoDetails(couponInput);
        change("coupon", "");
    };

    const enhancedHandleSubmit = (event) => {
        event.preventDefault();
        handleSubmit(event).then(() => {
            setPromptMessage("Success! Your trial has started. Enjoy exploring our features.");
            // wait for 3 seconds before closing the drawer'
            setTimeout(() => {
                onCompletePayment();
            }, 2000);
        });
    };

    useEffect(() => {
        // If existing plan is in the active list, choose it
        if (existingPlan && plans[existingPlan]) {
            change("planType", existingPlan);
            return;
        }

        // If the exisiting plan has a matching # of views, choose that plan
        const planWithViewNumber = Object.keys(plans).find((key) => {
            const views = parseFloat(plans[key]?.numberOfViews);
            return views >= 10 && views >= parseFloat(accountNumberOfViews);
        });
        if (existingPlan && accountNumberOfViews && planWithViewNumber) {
            change("planType", planWithViewNumber);
            return;
        }
    }, [existingPlan, existingInterval, accountNumberOfViews]);

    return (
        <div className={classNames(style.mainWrapper, { [style.isOnboarding]: isOnboarding })}>
            <form onSubmit={enhancedHandleSubmit} className={style.editBillingForm}>
                <div className={style.leftWrapper}>
                    <h3>Pro Plan Options</h3>
                    <Field
                        component={OptionRadioButtons}
                        setPromoDetails={setPromoDetails}
                        plans={plans}
                        name="planType"
                    />
                    <div
                        onClick={() => window.open("https://meetings.hubspot.com/jake889", "_blank")}
                        className={style.moreAccountsOption}
                    >
                        Not seeing a plan that fits your needs? <span>Book a Free Demo</span>
                    </div>
                    <h3>Plan Commitment</h3>
                    <Field
                        component={CycleOptionRadioButtons}
                        name="planCycle"
                        change={change}
                        toggleBilling={toggleBilling}
                        userType={userTypekey}
                    />
                    <div className={style.billingDetails}>
                        <h3>Customer Information</h3>
                        {error && <ErrorBar>{error}</ErrorBar>}
                        <Field
                            className={style.renderInputField}
                            component={RenderInput}
                            label="Name on Card"
                            name="cardholder"
                            type="text"
                            placeholder="Name on Card"
                            darkBorder
                            disabled={submitting}
                            validate={[required]}
                        />
                        <label>Card Number</label>
                        <CardNumberElement
                            key="number"
                            className={style.StripeElement}
                            style={inputStyle}
                            disabled={submitting}
                        />
                        <div className={style.doubleInputBox} style={{ marginBottom: "1rem" }}>
                            <div className={style.halfInputBox}>
                                <label>Expiry</label>
                                <CardExpiryElement
                                    key="expiry"
                                    className={style.StripeElement}
                                    style={inputStyle}
                                    disabled={submitting}
                                />
                            </div>
                            <div className={style.halfInputBox}>
                                <label>CVC</label>
                                <CardCVCElement
                                    key="cvc"
                                    className={style.StripeElement}
                                    style={inputStyle}
                                    disabled={submitting}
                                />
                            </div>
                        </div>
                        <Field
                            className={`${style.renderInputField} ${style.inputBox}`}
                            component={RenderInput}
                            label="Billing Address"
                            name="address"
                            type="text"
                            placeholder="Address"
                            darkBorder
                            disabled={submitting}
                            validate={[required]}
                        />
                        <div className={style.doubleInputBox}>
                            <div className={style.halfInputBox}>
                                <Field
                                    className={`${style.renderInputField} ${style.inputBox}`}
                                    component={RenderInput}
                                    label="State / Province"
                                    name="region"
                                    type="text"
                                    placeholder="State / Province"
                                    darkBorder
                                    labelHidden={true}
                                    disabled={submitting}
                                    validate={[required]}
                                />
                            </div>
                            <div className={style.halfInputBox}>
                                <Field
                                    className={`${style.renderInputField} ${style.inputBox}`}
                                    component={RenderInput}
                                    label="Country"
                                    name="country"
                                    type="text"
                                    placeholder="Country"
                                    darkBorder
                                    labelHidden={true}
                                    disabled={submitting}
                                    validate={[required]}
                                />
                            </div>
                        </div>
                        <div className={style.doubleInputBox}>
                            <div className={style.halfInputBox}>
                                <Field
                                    className={`${style.renderInputField} ${style.inputBox}`}
                                    component={RenderInput}
                                    label="City"
                                    name="city"
                                    type="text"
                                    placeholder="City"
                                    darkBorder
                                    labelHidden={true}
                                    disabled={submitting}
                                    validate={[required]}
                                />
                            </div>
                            <div className={style.halfInputBox}>
                                <Field
                                    className={`${style.renderInputField} ${style.inputBox}`}
                                    component={RenderInput}
                                    label="Zip / Postal code"
                                    name="postalCode"
                                    type="text"
                                    placeholder="Zip / Postal code"
                                    darkBorder
                                    labelHidden={true}
                                    disabled={submitting}
                                    validate={[required]}
                                />
                            </div>
                        </div>
                    </div>

                    <div className={style.stripeWrapper}>
                        <img alt="Lock Icon" src={LockIcon} />
                        <br />
                        <img alt="Stripe Logo" src={StripeLogo} />
                    </div>
                </div>
                <div className={style.rightWrapper}>
                    <div className={style.rightContent}>
                        <div className={style.summary}>
                            <h3>Order Summary</h3>
                            {selectedPlanCycle === "monthly" && (
                                <>
                                    <div className={style.coupon}>
                                        <Field
                                            className={`${style.renderInputField} ${style.couponField}`}
                                            component={RenderInput}
                                            label="Coupon"
                                            name="coupon"
                                            type="text"
                                            placeholder="Coupon code"
                                            labelHidden={true}
                                            disabled={couponDataLoading}
                                        />
                                        <Button
                                            small
                                            slate
                                            onClick={handlePromoCheck}
                                            disabled={!couponInput || couponDataLoading}
                                        >
                                            {couponDataLoading ? <LoadingDots /> : "Apply"}
                                        </Button>
                                    </div>
                                    {promoId && !couponAllowed && (
                                        <p className={style.error}>
                                            We're sorry, you cannot use this coupon with the plan you've selected
                                        </p>
                                    )}
                                    {couponError && <p className={style.error}>{couponError}</p>}
                                </>
                            )}
                            <div className={`${style.plan} ${promoId && couponAllowed && style.withCoupon}`}>
                                {/* <div className={style.details}>
                                    <p className={style.name}>
                                        Up To {toPrettyNumber(maxSpend, "cost")} <small>Monthly Spend</small>
                                    </p>
                                </div> */}
                                <div className={style.price}>
                                    {toPrettyNumber(originalPrice, "dollar")}
                                    <small> /mo</small>
                                </div>
                            </div>
                            {promoId && couponAllowed && (
                                <div className={style.couponLine}>
                                    <div className={style.details}>
                                        <p className={style.name}>
                                            Discount{" "}
                                            <span>
                                                <TagIcon /> {appliedCoupon}
                                            </span>
                                        </p>
                                    </div>
                                    <div className={style.price}>
                                        - {toPrettyNumber(amountOff, "dollar")}
                                        <small> /mo</small>
                                    </div>
                                </div>
                            )}
                            {promoId && couponAllowed && (
                                <div className={style.total}>
                                    <div className={style.details}>
                                        <p className={style.name}>Total</p>
                                    </div>
                                    <div className={style.price}>
                                        {toPrettyNumber(price, "dollar")}
                                        <small> /mo</small>
                                    </div>
                                </div>
                            )}
                            <div className={style.planDetails}>
                                <p className={style.accounts}>
                                    <strong>{numberOfViews}</strong> Client Accounts
                                </p>
                                <p className={style.accounts}>Unlimited Users</p>
                                <p className={style.accounts}>
                                    {selectedPlanCycle === "monthly"
                                        ? "Monthly"
                                        : selectedPlanCycle === "quarterly"
                                        ? "Quarterly"
                                        : "Yearly"}{" "}
                                    Commitment
                                    {"  "}
                                    {selectedPlanCycle !== "monthly" && (
                                        <span className={style.savings}>
                                            ({toPrettyNumber(saving, "dollar")} Savings)
                                        </span>
                                    )}
                                </p>
                            </div>
                            <Button className={style.submit} type="submit" disabled={submitting} vibrantBlue>
                                {submitting ? "Processing..." : "Complete Payment"}
                            </Button>
                            <p className={style.disclaimer}>
                                By clicking '<b>Complete Payment</b>' you are agreeing to our{" "}
                                <a href="https://hawke.ai/terms-conditions/" target="_blank" rel="noopener noreferrer">
                                    Terms & Conditions
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </form>
            <TooltipDark id="upgradeTooltip" proximity={20} />
        </div>
    );
};
