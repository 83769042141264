import createReducer from "lib/createReducer";
import clearReducer from "lib/clearReducer";
import { handle } from "redux-pack";
import types from "./types";

/**
 * Produce an array for adwords tags
 * @param {array} tags
 * @returns {array}
 */

function getAdwordsTags(tags) {
    if (tags.length === 0) return tags;
    return tags.map(({ name }) => name);
}

/**
 * Produce an array for facebook tags
 * @param {array} tags
 * @returns {array}
 */

function getFacebookTags(tags) {
    if (tags.length === 0) return tags;
    return tags.map(({ name }) => name);
}

/**
 * Produce an array for bing tags
 * @param {array} tags
 * @returns {array}
 */

function getBingTags(tags) {
    if (tags.length === 0) return tags;
    return tags.map((name) => name);
}

/**
 * Produce an tag array based on the platform
 * @param {string} platform facebook | adwords | bing
 * @param {object} tags rest of campaign object containing tag keys
 * @return { array }
 */

export function getTags(platform, tags) {
    if (platform === "facebook" && Array.isArray(tags.adlabels)) {
        return getFacebookTags(tags.adlabels);
    }
    if (platform === "adwords" && Array.isArray(tags.labels)) {
        return getAdwordsTags(tags.labels);
    }

    if (platform === "bing" && Array.isArray(tags.labels)) {
        return getBingTags(tags.labels);
    }

    return [];
}

const accountOptions = {
    analyticsV4: {
        analyticsV4Accounts: {
            humanReadable: "Google Analytics Account",
        },
        analyticsV4Properties: {
            humanReadable: "Google Analytics Property",
        },
    },
    adwords: {
        adwords: {
            humanReadable: "Google Ads Account",
        },
        "adwords-campaigns": {
            humanReadable: "Google Ads Campaign(s)",
        },
    },
    facebook: {
        facebook: {
            humanReadable: "Meta Ads Account",
        },
        "facebook-campaigns": {
            humanReadable: "Meta Ads Campaign(s)",
        },
    },
    organicFacebook: {
        organicFacebook: {
            humanReadable: "Organic Facebook Account",
        },
        "organicFacebook-page": {
            humanReadable: "Organic Facebook Page",
        },
    },
    organicInstagram: {
        organicInstagram: {
            humanReadable: "Organic Instagram Account",
        },
        "organicInstagram-page": {
            humanReadable: "Organic Instagram Page",
        },
    },
    bing: {
        bing: {
            humanReadable: "Microsoft Advertising Account",
        },
        "bing-campaigns": {
            humanReadable: "Microsoft Advertising Campaign(s)",
        },
    },
    linkedin: {
        linkedin: {
            humanReadable: "LinkedIn Marketing Account",
        },
        "linkedin-campaigns": {
            humanReadable: "LinkedIn Marketing Campaign(s)",
        },
    },
    tiktok: {
        tiktok: {
            humanReadable: "TikTok Marketing Account",
        },
        "tiktok-campaigns": {
            humanReadable: "TikTok Marketing Campaign(s)",
        },
    },
    pinterest: {
        pinterest: {
            humanReadable: "Pinterest Marketing Account",
        },
        "pinterest-campaigns": {
            humanReadable: "Pinterest Marketing Campaign(s)",
        },
    },
    maropost: {
        maropost: {
            humanReadable: "Maropost Account",
        },
        "maropost-campaigns": {
            humanReadable: "Maropost Marketing Campaign(s)",
        },
    },
    klaviyo: {
        klaviyo: {
            humanReadable: "Klaviyo Account",
        },
        "klaviyo-campaigns": {
            humanReadable: "Klaviyo Campaign(s)",
        },
    },
    slack: {
        slack: {
            humanReadable: "Slack Account",
        },
    },
    shopify: {
        shopify: {
            humanReadable: "Shopify Account",
        },
    },
    bigQuery: {
        bigQuery: {
            humanReadable: "Google BigQuery Dataset",
        },
        "bigQuery-dataset": {
            humanReadable: "Google BigQuery Dataset",
        },
    },
    stackAdapt: {
        stackAdapt: {
            humanReadable: "StackAdapt Account",
        },
        "stackAdapt-campaigns": {
            humanReadable: "StackAdapt Campaign(s)",
        },
    },
    mailchimp: {
        mailchimp: {
            humanReadable: "Mailchimp Account",
        },
        "mailchimp-campaigns": {
            humanReadable: "Mailchimp Campaign(s)",
        },
    },
};

export const defaultState = {
    pending: true,
    accountsLoading: null,
    accountsHasError: null,
    campaignsLoading: null,
    integrations: accountOptions,
    deleteConnectionPending: null,
    accounts: {
        accounts: [],
        webProperties: [],
        profiles: [],
        adwords: [],
        bing: [],
        facebook: [],
        pinterest: [],
    },
    searchConsole: [],
    googleBusinessProfile: [],
    googleBusinessProfileLocations: [],
    campaigns: {
        adwords: [],
        bing: [],
        facebook: [],
        linkedin: [],
        tiktok: [],
        pinterest: [],
    },
    connections: {},
};

export default clearReducer(defaultState)(
    createReducer(defaultState, {
        [types.CHANGE_ACCOUNT_CONNECTION]: (state, action) =>
            handle(state, action, {
                finish: (prevState) => ({
                    ...prevState,
                    connections: {
                        [action.meta.platform]: action.meta.connectionId,
                    },
                }),
            }),
        [types.CLEAR_REDUCER]: (state, action) =>
            handle(state, action, {
                finish: (prevState) => ({
                    ...defaultState,
                }),
            }),
        [types.FETCH_CAMPAIGNS]: (state, action) =>
            handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    campaignsLoading: true,
                }),
                success: (prevState) => {
                    const { data } = action.payload;
                    const dataFormatted = data.isCancelledReq ? {} : data;

                    return {
                        ...prevState,
                        campaignsLoading: false,
                        campaigns: {
                            ...prevState.campaigns,
                            ...Object.keys(dataFormatted).reduce(
                                (cache, key) => ({
                                    ...cache,
                                    [key]: dataFormatted[key].map(
                                        ({ name, id, campaignName, advertisingChannelType, ...tags } = {}) => ({
                                            label: `${name ? name : campaignName} - ${id}`,
                                            value: id,
                                            tags: getTags(key, tags),
                                            network: advertisingChannelType,
                                        }),
                                    ),
                                }),
                                {},
                            ),
                        },
                    };
                },
                finish: (prevState) => ({
                    ...prevState,
                    campaignsLoading: false,
                }),
            }),
        [types.GET_ACCOUNTS_REQUEST](state, action) {
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    accountsLoading: true,
                    accounts: undefined,
                }),
                success: (prevState, { payload: { analytics = {}, ...rest } = {} }) => ({
                    ...prevState,
                    accounts: {
                        ...prevState.accounts,
                        ...analytics,
                        ...rest,
                    },
                    accountsHasError: false,
                }),
                failure: (prevState) => ({
                    ...prevState,
                    accountsHasError: true,
                }),
                finish: (prevState) => ({
                    ...prevState,
                    accountsLoading: false,
                }),
            });
        },
        [types.GET_SEARCHCONSOLE_ACCOUNTS](state, action) {
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    searchConsole: [],
                }),
                success: (prevState, { payload: { data = [] } = {} }) => ({
                    ...prevState,
                    searchConsole: data,
                }),
            });
        },
        [types.GET_GOOGLE_BUSINESS_PROFILE_ACCOUNTS](state, action) {
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    googleBusinessProfile: [],
                    hasGoogleBusinessProfilePermissionIssue: false,
                }),
                success: (prevState, { payload: { data = [] } = {} }) => ({
                    ...prevState,
                    googleBusinessProfile: data,
                }),
                failure: (prevState) => ({
                    ...prevState,
                    hasGoogleBusinessProfilePermissionIssue: true,
                }),
            });
        },
        [types.GET_GOOGLE_BUSINESS_PROFILE_LOCATIONS](state, action) {
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    googleBusinessProfileLocations: [],
                }),
                success: (prevState, { payload: { data = [] } = {} }) => ({
                    ...prevState,
                    googleBusinessProfileLocations: data,
                }),
            });
        },
    }),
);
